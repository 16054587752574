<template>
    <b-row>
        <b-col :md="displayPhases?6:12">
            <field-select
                    :model.sync="entity.workflow"
                    name="workflow"
                    :options="autocompleteWorkflows"
                    label="_display"
                    :is-required="isRequired"

                    @input="workflowChange"
            />
        </b-col>
        <b-col v-if="displayPhases"
               md="6">
            <field-select
                    :model.sync="entity.phase"
                    name="phase"
                    :options="autocompletePhases"
                    label="name"
                    :is-required="isRequired"
                    :placeholder="$t('Choose a phase')"
            />
        </b-col>
    </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldSelect from './Select2.vue'
import useAPI from '../../utils/useAPI'

export default {
  components: {
    FieldSelect
  },
  props: {
    withPhases: {
      type: Boolean,
      default: true
    },
    entity: {
      type: Object,
      default: () => {}
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    // const entityLocal = ref(props.entity)
    // const workflowLocal = ref(props.entity.workflow)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteWorkflows,
      autocompletePhases
    } = useAPI()

    const displayPhases = computed(() => {
      return props.entity.workflow != null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => props.entity.workflow, val => {
    //   console.log(val)
    //   if (val == null) {
    //     props.entity.phase = null
    //   } else {
    //     updatePhases()
    //   }
    // })

    // watch(entityLocal, val => {
    //   console.log(val)
    //   if (val == null) {
    //     entityLocal.value.phase = null
    //
    //     emit('update:entity', entityLocal.value)
    //   } else {
    //     updatePhases()
    //   }
    // })

    // watch(() => entityLocal, val => {
    //   console.log(val)
    //   // if (val == null) {
    //   //   entityLocal.value.phase = null
    //   //
    //   //   emit('update:entity', entityLocal.value)
    //   // } else {
    //   //   updatePhases()
    //   // }
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompletePhases } = useAPI()

    // const updatePhases = () => {
    //   if (props.entity.workflow != null) {
    //     fetchAutocompletePhases({
    //       searchCriteria: [
    //         {
    //           column_name: 'workflow',
    //           comparator: '=',
    //           search_text: props.entity.workflow.id
    //         }
    //       ]
    //     })
    //   }
    // }
    const updatePhases = () => {
      if (props.entity.workflow != null) {
        fetchAutocompletePhases({
          filters: [{
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: "AND",
                operator: 'equal',
                field: 'workflow',
                value: props.entity.workflow.id
              }
            ]
          }]
          // filters: [{
          //   groupOperator: 'AND',
          //   group: [
          //     {
          //       comparator: 'equal',
          //       field: 'workflow',
          //       value: props.entity.workflow.id
          //     }
          //   ]
          // }]
        })
      }
    }

    const workflowChange = (newWorkflow) => {
      emit('input')
      // console.log(newWorkflow)
      if (newWorkflow == null) {
        // entityLocal.value.phase = null
        props.entity.phase = null

        // emit('update:entity', entityLocal.value)
      } else {
        updatePhases()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updatePhases()

    return {
      // Components
      // entityLocal,

      // Data

      // Computed
      autocompleteWorkflows,
      autocompletePhases,
      displayPhases,

      // Methods
      workflowChange,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <b-row>
    <b-col cols="12">
      <field-input
          :name="'customerRequestNumber'"
          :model.sync="outgoingInvoice.customerPurchaseOrderNumber"
      />
    </b-col>

    <!--          Customer request date-->
    <b-col cols="12">
      <field-date
          :name="'signatureDate'"
          :model.sync="outgoingInvoice.customerPurchaseOrderDate"
      />
    </b-col>

    <!-- customer order/ Signed quote-->
    <b-col cols=12
           class="mb-2">
      <field-file
          :singleFile.sync="outgoingInvoice.customerPurchaseOrder"
          :documentType="'incomingInvoice'"
          :listDisplay="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldDate from '@/components/input/Date'
import FieldFile from '@/components/input/File'
import FieldInput from '@/components/input/Input'

export default {
  components: {
    FieldInput,
    FieldDate,
    FieldFile
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
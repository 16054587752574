<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="isDownloadable"
      :isViewable="isViewable"
      :is-selected="isSelected"
      @click="isParentMethodPriority?$emit('click'):download()"
      @download="download"
      @toRoute="isParentMethodPriority?$emit('toRoute'):''"
      @view="$emit('view')"
      @edit="$emit('edit')"
      @pay="$emit('pay')"
      @delete="$emit('delete')"
  >
    <template #bodyContent>
      <div class="d-flex justify-content-between h5">
        <div class="text-truncate">
          {{ outgoingInvoice.customerCompany ? outgoingInvoice.customerCompany._display : outgoingInvoice.customerIndividual._display }}
        </div>
        <div class="text-nowrap">
          {{ currency(outgoingInvoice.totalAmount) }}
        </div>
      </div>
      <small class="d-flex justify-content-between">
        <div class="text-truncate">
          <i>{{ outgoingInvoice.billNumber }}</i>
        </div>
        <div>
          <i>{{ outgoingInvoice.billingDate|moment('L') }}</i>
        </div>
      </small>

    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '@/utils/filter'
import { useOutgoingInvoices } from '../../../views/sales/outgoingInvoices/useOutgoingInvoices'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => { }
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useOutgoingInvoices()

    const download = () => {
      downloadOutgoingInvoice(props.outgoingInvoice)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed

      // Methods
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <b-row>
    <b-col md="12">
      <field-file
          :singleFile.sync="incomingInvoice.receipt"
          :documentType="'incomingInvoice'"
          :isRequired="true"
          @delete="deleteFile"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldFile from '../../../../components/input/File'
import CardDocument from '../../../card/Document'

export default {
  components: {
    FieldFile,
    CardDocument
  },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const deleteFile = () => {
      props.incomingInvoice.receipt = null
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      deleteFile,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
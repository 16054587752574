<template>
  <b-row>
    <b-col md="12">
      <field-work-flows
          :entity.sync="outgoingInvoice"
      />
    </b-col>


<!--    <b-col :md="outgoingInvoice.workflow==null?12:6">-->
<!--      <field-select-->
<!--          :model.sync="outgoingInvoice.workflow"-->
<!--          :name="'workflow'"-->
<!--          :options="workflows"-->
<!--          label="_display"-->
<!--          class="w-100"-->
<!--          @input="resetOfferPhase"-->
<!--      />-->
<!--    </b-col>-->

<!--    <b-col md="6"-->
<!--           v-if="outgoingInvoice.workflow">-->
<!--      <field-select-->
<!--          :model.sync="outgoingInvoice.phase"-->
<!--          :name="'phase'"-->
<!--          :placeholder="$t('Choose a phase')"-->
<!--          :options="availablePhases"-->
<!--          :label="'name'"-->
<!--          class="w-100"-->
<!--          :key="componentKey"-->
<!--      />-->
<!--    </b-col>-->
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import useAPI from '@/utils/useAPI'
import FieldSelect from '@/components/input/Select'
import FieldWorkFlows from '../../../input/Workflow.vue'

export default {
  components: {
    FieldSelect,
    FieldWorkFlows
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    const availablePhases = computed(() => {
      if (isObject(props.outgoingInvoice.workflow)) {
        return workflows.value.find(w => w.id == props.outgoingInvoice.workflow.id).phases
      } else if (workflows.value.some(w => w.id == props.outgoingInvoice.workflow)) {
        return workflows.value.find(w => w.id == props.outgoingInvoice.workflow).phases
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetOfferPhase = () => {
      props.outgoingInvoice.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      workflows,
      availablePhases,

      // Methods
      resetOfferPhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
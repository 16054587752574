import store from '@/store'
import { computed } from '@vue/composition-api'

export const useOutgoingInvoices = () => {

  const submitValidatedOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      if ('id' in outgoingInvoice) {
        updateOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice/addOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice/updateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice/removeOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const validateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice/validateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOutgoingInvoice = (outgoingInvoice) => {
    store.dispatch('outgoingInvoice/printOutgoingInvoice', outgoingInvoice)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getOutgoingInvoiceStatus = (outgoingInvoice) => {
    if (outgoingInvoice._filters.some(flt => flt.key == '_filter_isDraftStatus' && flt.value == true)) {
      return 'draft'
    } else if (outgoingInvoice._filters.some(flt => flt.key == '_filter_isSentStatus' && flt.value == true)) {
      return 'sent'
    }
  }

  const getOutgoingInvoiceIconStatus = (outgoingInvoice) => {
    if (getOutgoingInvoiceStatus(outgoingInvoice) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
    else return { variant: 'primary', icon: 'paper-plane' }
  }

  return {
    submitValidatedOutgoingInvoice,
    removeOutgoingInvoice,
    validateOutgoingInvoice,
    downloadOutgoingInvoice,
    getOutgoingInvoiceStatus,
    getOutgoingInvoiceIconStatus
  }
}
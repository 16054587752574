var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.title,"size":"xl","scrollable":"","hide-footer":""},model:{value:(_vm.isOpenLocal),callback:function ($$v) {_vm.isOpenLocal=$$v},expression:"isOpenLocal"}},[_c('b-tabs',{scopedSlots:_vm._u([{key:"tabs-start",fn:function(){return [_c('div',{staticClass:"my-auto mr-1"},[_c('icon',{staticClass:"cursor-pointer px-50",attrs:{"icon":"chevron-circle-left","active-hover":true},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeMonth('previous')}}}),_c('icon',{staticClass:"cursor-pointer px-50",attrs:{"icon":"chevron-circle-right","active-hover":true},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeMonth('next')}}})],1)]},proxy:true}])},[_c('b-tab',{attrs:{"active":_vm.defaultDisplay=='customersInvoices',"title":_vm.capitalize(_vm.$tc('customerInvoice', _vm.outgoingInvoicesContext.total_results))+ ' ('+_vm.outgoingInvoicesContext.total_results+')'}},[_c('b-card-text',[_c('customer-invoices-view3',{attrs:{"indicator-start":_vm.start,"indicator-end":_vm.end}})],1)],1),_c('b-tab',{attrs:{"active":_vm.defaultDisplay!='customersInvoices',"title":_vm.capitalize(_vm.$tc('supplierInvoice', _vm.incomingInvoicesContext.total_results))+ ' ('+_vm.incomingInvoicesContext.total_results+')'}},[_c('b-card-text',[_c('suppliers-invoices-view3',{attrs:{"indicator-start":_vm.start,"indicator-end":_vm.end}})],1)],1),_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$tc('documents',2))}},[_c('b-card-text',[_c('document-content',{attrs:{"document":{
          name: _vm.$t('LiaisonForm') + ' (Excel)',
          fileName: _vm.title,
          extension: 'xlsx'
          },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadLiaisonForm,"click":_vm.downloadLiaisonForm}}),_c('document-content',{attrs:{"document":{
          name: _vm.$t('PurchasesMerging'),
          fileName: _vm.capitalize(_vm.$t('purchases:')) + ' ' + _vm.title,
          extension: 'pdf'
          },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadDetailPurchases,"click":_vm.downloadDetailPurchases}}),_c('document-content',{attrs:{"document":{
          name: _vm.$t('SalesMerging'),
          fileName: _vm.capitalize(_vm.$t('sales:')) + ' ' + _vm.title,
          extension: 'pdf'
          },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadDetailSales,"click":_vm.downloadDetailSales}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
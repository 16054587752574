<template>
  <div>
    <select-group-level-item
        :item="item"

        @click="$emit('click', $event)"
    />

    <select-group-level-items
        v-for="child in item.childs"
        class="ml-2"
        :item="child"

        @click="$emit('click', $event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import SelectGroupLevelItem     from './SelectGroupLevelItem.vue'

export default {
  name: 'SelectGroupLevelItems',
  components: { SelectGroupLevelItem },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
import store from '../index'
import { computed } from '@vue/composition-api'

export default function useDepartmentApi () {
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================

  const fetchAutocompleteDepartments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('department/getAutocompleteDepartments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchDepartments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('department/getDepartments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchHierarchicalDepartments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('department/getHierarchicalDepartments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const autocompleteDepartments = computed(() => {
    return store.getters['department/getAutocompleteDepartments']
  })

  const departments = computed(() => {
    return store.getters['department/getDepartments']
  })

  const departmentsContext = computed(() => {
    return store.getters['department/getDepartmentsContext']
  })

  const hierarchicalDepartments = computed(() => {
    return store.getters['department/getHierarchicalDepartments']
  })


  return {
    // Fetchers
    fetchAutocompleteDepartments,
    fetchDepartments,
    fetchHierarchicalDepartments,

    // Computed
    autocompleteDepartments,
    departments,
    departmentsContext,
    hierarchicalDepartments
  }
}
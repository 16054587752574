<template>
  <div>
    <transition name="fade">
      <b-card
          v-if="Object.entries(expensesIndicator).length !== 0">
        <b-row>

          <!--      Left col-->
          <b-col md="6">
            <h1 class="text-danger">{{ currency(expensesIndicator.expenses) }}</h1>
            <h3 class="text-danger">{{ $t('CumulativeExpensesHT') }}</h3>

            <div class="text-primary my-2">
              <span>{{ capitalize($t('from:')) }}
                <b>{{ startLocal|moment('LL') }}</b>
              </span>
              <br/>
              <span>{{ capitalize($t('to:')) }}
                <b>{{ endLocal|moment('LL') }}</b>
              </span>
            </div>
          </b-col>

          <!--            Right col-->
          <b-col md="6">
            <chart-columns
                :series="yAxix"
                :labels="xAxis"
                :isCurrencies="true"
                :displays="false"
                @click="columnSelected"
            />
          </b-col>

          <b-col cols="12">
            <div class="border-bottom"/>
          </b-col>

        </b-row>

        <b-row>
          <b-col md="6"
                 class="text-center mt-2">
            <h3 class="font-large-1 font-weight-bold mb-0">
              {{ currency(expensesIndicator.expensesAverage) }} {{ $t('HT') }}
            </h3>
            <span class="font-medium-1">{{ capitalize($t('averageMonthly')) }}</span>
          </b-col>

          <b-col md="6">
            <b-button
                class="customButton mt-1"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                @click="$router.push('SupplierReceivable')"
                :block="true"
            >
              <h3 class="font-large-1 font-weight-bold mb-0 text-primary">
                {{ currency(expensesIndicator.supplierReceivable) }}
              </h3>
              <span class="font-medium-1">
                {{ $t('SupplierReceivable') }} {{ $t('TTC') }}
              </span>
            </b-button>

          </b-col>

        </b-row>

      </b-card>
    </transition>

    <!--    Detail modal-->
<!--    <prompt-detail-revenue-->
<!--        :date="modalTitle"-->
<!--        :isOpen.sync="isModalDetailOpen"-->
<!--        :elementsToDetail="elementsToDetail"-->
<!--    />-->
    <prompt-details
        :month-str="modalTitle"
        :is-open.sync="isModalDetailOpen"
        default-display="suppliersInvoices"

        @changeToPrevious="$emit('changeToPrevious')"
        @changeToNext="$emit('changeToNext')"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../../utils/filter'
import { useIndicators } from '../useIndicators'

import store from '@/store'
import moment from 'moment'
import ChartColumns from '../../chart/Columns'
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
import PromptDetails from '../../../views/indicators/components/_promptDetails.vue'
// import PromptDetailRevenue from '../components/_promptDetailRevenue'
import useAPI from '../../../utils/useAPI'
// import awaitingExportExpensesIndicators from '../../indicatorDetails/AwaitingExportExpensesIndicators'

export default {
  directives: {
    Ripple,
  },
  components: {
    ChartColumns,
    PromptDetails,
    // PromptDetailRevenue
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    globalSeries: {
      type: Array,
      default: null
    }
  },
  setup (props, { emit }) {
    const {
      fetchExpensesIndicator,
      fetchAwaitingExportExpensesIndicator,
      expensesIndicator,
    } = useAPI()

    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startLocal = ref(moment(props.start))
    const endLocal = ref(moment(props.end))

    const isModalDetailOpen = ref(false)
    const modalTitle = ref(null)
    const elementsToDetail = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const xAxis = computed(() => {
      return Object.keys(expensesIndicator.value.expensesByMonths)
      // let months = []
      // Object.keys(expensesIndicator.value.expensesByMonths).forEach(em => months.push(capitalize(moment(em).format('MMMM YYYY'))))
      //
      // return months
    })

    const yAxix = computed(() => {
      let output = []
      let expensesByMonths = expensesIndicator.value.expensesByMonths
// console.log(expensesByMonths)
      Object.keys(expensesByMonths).forEach(item => output.push(expensesByMonths[item].expenses))

      return [
        {
          name: capitalize(i18n.t('outgoing')),
          data: output
        }]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(expensesIndicator, val => {
      emit('update:expensesSeries', val.expensesByMonths)
    })

    watch(startLocal, () => {
      fetchIndicators()
    })

    watch(endLocal, () => {
      fetchIndicators()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const fetchIndicators = () => {
      if (startLocal.value != null && endLocal.value != null) {
        fetchExpensesIndicator({
          start: moment(props.start).format('YYYY-MM-DD'),
          end: moment(props.end).format('YYYY-MM-DD'),
          employees: []
        })
      }
    }

    const columnSelected = ({ position }) => {
      let detailedTurnoverSelected = Object.values(props.globalSeries[0].data)[position].detail
      let detailedExpensesSelected = Object.values(expensesIndicator.value.expensesByMonths)[position].detail

      modalTitle.value = capitalize(Object.keys(expensesIndicator.value.expensesByMonths)[position])

      elementsToDetail.value = {
        sales: {
          outgoingInvoices: detailedTurnoverSelected.outgoingInvoiceEntities
        },
        expenses: {
          charges: detailedExpensesSelected.chargeEntities,
          incomingInvoices: detailedExpensesSelected.incomingInvoiceEntities,
          mileageAllowances: detailedExpensesSelected.mileageAllowanceEntities,
          salaries: detailedExpensesSelected.salaryEntities
        }
      }

      isModalDetailOpen.value = true
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchExpensesIndicator({
      start: moment(props.start).format('YYYY-MM-DD'),
      end: moment(props.end).format('YYYY-MM-DD'),
      employees: []
    })

    fetchAwaitingExportExpensesIndicator()

    return {
      // Components
      currency,
      capitalize,

      // Data
      startLocal,
      endLocal,
      isModalDetailOpen,
      modalTitle,
      elementsToDetail,

      // Computed
      expensesIndicator,
      // getCurrentExpensesLocal,
      // expensesByMonthsLocal,
      xAxis,
      yAxix,
      // averageMonthlyLocal,
      // supplierReceivableTotalAmount,

      // Methods
      columnSelected,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    start: function (val) {
      this.startLocal = moment(val)
    },
    end: function (val) {
      this.endLocal = moment(val)
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <b-col cols="12"  class="includedContacts">
    <b-row>

      <!--    Contact-->
      <b-col md="8">
        <field-contact
            :name="'contact'"
            :contact.sync="includedContact.company"
            :is-initial-contact-company="true"
            :is-company-only="true"
            :is-required="true"
        />
<!--        <field-contact-->
<!--            :name="'contact'"-->
<!--            :customerCompany.sync="includedContact.company"-->
<!--             :listIndex="index"-->
<!--            :isCompanyOnly="false"-->
<!--            :isRequired="true"-->
<!--            :withMe="true"-->
<!--        />-->
      </b-col>

      <!--      Number-->
      <b-col md="4"
             class="d-flex">
        <field-input
            :name="'number2'"
            :model.sync="includedContact.number"
            :rules="'required|integer'"
            :listIndex="index"
            class="w-100"
        />

        <div class="py-50 px-25" style="margin-top: 17px;">
          <button-delete
              size="sm"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="!allowRemove"
              @click.native.stop="$emit('removeIncludedContact')"
          />
        </div>

<!--        <div-->
<!--            class="text-danger cursor-pointer pl-2"-->
<!--            style="padding-top: 30px"-->
<!--            @click="$emit('removeIncludedContact')"-->
<!--        >-->
<!--          <icon-->
<!--              icon="times"-->
<!--              :disabled="!allowRemove"-->
<!--          />-->
<!--        </div>-->
      </b-col>

    </b-row>
  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'

import FieldContact from '../../../input/Contact2.vue'
import FieldInput from '@/components/input/Input'
import ButtonDelete from '../../../button/Delete'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    FieldContact,
    FieldInput,
    useAPI,
    ButtonDelete
  },
  props: {
    includedContact: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    allowRemove: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.includedContact)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.includedContacts {
  .customButton {
    margin-top: 7px!important;
  }
}

</style>
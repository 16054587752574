<template>
  <div>
    <transition name="fade">
      <b-card
          v-if="Object.entries(salesIndicator).length !== 0"
      >
        <b-row>

          <!--      Left col-->
          <b-col md="6">
            <h1 class="text-success">{{ currency(salesIndicator.turnover) }}</h1>
            <h3 class="text-success">{{ $t('CumulativeTurnoverHT') }}</h3>

            <div class="text-primary my-2">
              <span>{{ capitalize($t('from:')) }}
                <b>{{ startLocal|moment('LL') }}</b>
              </span>
              <br/>
              <span>{{ capitalize($t('to:')) }}
                <b>{{ endLocal|moment('LL') }}</b>
              </span>
            </div>
          </b-col>

          <!--      Right col-->
          <b-col md="6">
            <chart-columns
                :series="yAxix"
                :labels="xAxis"
                :isCurrencies="true"
                :displays="false"
                @click="columnSelected"

            />
          </b-col>

          <b-col cols="12">
            <div class="border-bottom"/>
          </b-col>

        </b-row>

        <b-row>
          <b-col md="6"
                 class="text-center mt-2">
            <h3 class="font-large-1 font-weight-bold mb-0">
              {{ currency(salesIndicator.turnoverAverage) }} {{ $t('HT') }}
            </h3>
            <span class="font-medium-1">{{ capitalize($t('averageMonthly')) }}</span>
          </b-col>

          <b-col md="6">
            <b-button
                class="customButton mt-1"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                @click="$router.push('CustomerReceivable')"
                :block="true"
            >
              <h3 class="font-large-1 font-weight-bold mb-0 text-primary">
                {{ currency(salesIndicator.customerReceivable) }}
              </h3>
              <span class="font-medium-1">
                {{ capitalize($t('CustomerReceivable')) }} {{ $t('TTC') }}
              </span>
            </b-button>

          </b-col>
        </b-row>

      </b-card>
    </transition>


    <!--    Detail modal-->
    <!--    <prompt-detail-revenue-->
    <!--        :date="modalTitle"-->
    <!--        :isOpen.sync="isModalDetailOpen"-->
    <!--        :elementsToDetail="elementsToDetail"-->
    <!--    />-->
    <prompt-details
        :month-str="modalTitle"
        :is-open.sync="isModalDetailOpen"
        default-display="customersInvoices"

        @changeToPrevious="$emit('changeToPrevious')"
        @changeToNext="$emit('changeToNext')"
    />

    <!--    {{salesGeneral}}-->
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../../utils/filter'

import store from '@/store'
import moment from 'moment'
import ChartColumns from '../../chart/Columns'
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
// import PromptDetailRevenue from '../components/_promptDetailRevenue'
import PromptDetails from '../../../views/indicators/components/_promptDetails.vue'
import useAPI from '../../../utils/useAPI'

export default {
  directives: {
    Ripple,
  },
  components: {
    ChartColumns,
    // PromptDetailRevenue,
    PromptDetails,
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    globalSeries: {
      type: Array,
      default: null
    }
  },
  setup (props, { emit }) {

    const { fetchSalesIndicator, fetchAwaitingExportSalesIndicator, salesIndicator } = useAPI()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startLocal = ref(moment(props.start))
    const endLocal = ref(moment(props.end))

    const isModalDetailOpen = ref(false)
    const modalTitle = ref(null)
    const elementsToDetail = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const xAxis = computed(() => {
      return Object.keys(salesIndicator.value.turnoverByMonths)
    })

    const yAxix = computed(() => {
      let output = []
      let turnoverByMonths = salesIndicator.value.turnoverByMonths

      Object.keys(turnoverByMonths).forEach(item => output.push(turnoverByMonths[item].turnover))

      return [
        {
          name: capitalize(i18n.t('incoming')),
          data: output
        }]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(salesIndicator, val => {
      emit('update:salesSeries', val.turnoverByMonths)
    })

    watch(startLocal, (val) => {
      fetchIndicators()
    })

    watch(endLocal, (val) => {
      fetchIndicators()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const fetchIndicators = () => {
      if (startLocal.value != null && endLocal.value != null) {
        fetchSalesIndicator({
          dates: {
            start: startLocal.value.format('YYYY-MM-DD'),
            end: endLocal.value.format('YYYY-MM-DD')
          }
        })
      }
    }

    const columnSelected = ({ position }) => {
      let detailedTurnoverSelected = Object.values(salesIndicator.value.turnoverByMonths)[position].detail
      let detailedExpensesSelected = Object.values(props.globalSeries[1].data)[position].detail

      // console.log(detailedTurnoverSelected)
      // console.log(detailedTurnoverSelected.outgoingInvoiceEntities)

      modalTitle.value = capitalize(Object.keys(salesIndicator.value.turnoverByMonths)[position])

      elementsToDetail.value = {
        sales: {
          outgoingInvoices: detailedTurnoverSelected.outgoingInvoiceEntities
        },
        expenses: {
          charges: detailedExpensesSelected.chargeEntities,
          incomingInvoices: detailedExpensesSelected.incomingInvoiceEntities,
          mileageAllowances: detailedExpensesSelected.mileageAllowanceEntities,
          salaries: detailedExpensesSelected.salaryEntities
        }
      }

      isModalDetailOpen.value = true
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    fetchSalesIndicator({
      dates: {
        start: moment(props.start).format('YYYY-MM-DD'),
        end: moment(props.end).format('YYYY-MM-DD')
      }
    })

    fetchAwaitingExportSalesIndicator()

    return {
      // Components
      currency,
      capitalize,

      // Data
      startLocal,
      endLocal,
      isModalDetailOpen,
      modalTitle,
      elementsToDetail,

      // Computed
      xAxis,
      yAxix,
      salesIndicator,

      // Methods
      columnSelected,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    start: function (val) {
      this.startLocal = moment(val)
    },
    end: function (val) {
      this.endLocal = moment(val)
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <div>

    <b-form-group
        :label-for="name+ (index != -1?(' '+(index+1)):'')"
        class="input-group-merge"
    >
      <validation-provider
          v-if="isRequired"
          #default="{ errors }"
          :name="$tc(name)+ (index != -1?(' '+(index+1)):'')"
          rules="required"
      >
        <b-input-group>
          <b-input-group-prepend
              v-if="!isPlaintext"
              is-text
              v-b-tooltip.hover="capitalize($t(name))"
              class="text-muted"
          >
            <icon
                class="text-muted"
                icon="list-ol"
            />
          </b-input-group-prepend>
          <b-form-input
              :placeholder="capitalize($t(placeholder))"
              class="search-product"
              v-model="localSearchQuery"
              :plaintext="isPlaintext"
              :list-index="index"
              :required="isRequired"
              :state="errors.length > 0 ? false:null"

              @focus="isFocus=true"
              @blur="isFocus=false"
          />
          <b-input-group-append
              v-if="!isPlaintext"
              is-text
          >
            <icon
                icon="chevron-down"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>

      </validation-provider>
    </b-form-group>


    <b-card
        v-if="displayContent"
        no-body
        class="mt-1 overflow-auto"
        style="max-height: 300px"
    >
      <select-group-level-items
          v-for="option in optionsLocal"
          :item="option"

          @click="selectItem"
      />

      <!--      @click="$emit('click', $event); displayContent=false; modelLocal=$event;"-->

      <span
          v-if="optionsLocal.length == 0"
          class="ml-50"
      >
        <small>
          <i>{{ capitalize($tc('noAccountingLine', 1)) }}</i>
        </small>
      </span>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import SelectGroupLevelItem     from './components/SelectGroupLevelItem.vue'
import SelectGroupLevelItems    from './components/SelectGroupLevelItems.vue'
import { clone, isObject }      from '../../utils/utils'
import { capitalize }           from '../../utils/filter'
import FormInput                from './Input.vue'
import { ValidationProvider }   from 'vee-validate'

export default {
  components: { ValidationProvider, FormInput, SelectGroupLevelItems, SelectGroupLevelItem },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    isPlaintext: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'search'
    },
    index: {
      type: Number,
      default: 1
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.options))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localSearchQuery = ref('')
    const optionsLocal = ref(props.options)
    const displayContent = ref(false)
    const isFocus = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const modelLocal = computed({
      get () {
        return props.model
      },
      set (val) {
        emit('update:model', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    let delayTimer
    watch(localSearchQuery, val => {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        populateSearch()
      }, 500)
    })

    watch(isFocus, val => {
      // console.log(val)
      if (val == true) {
        displayContent.value = val
      }
    })

    watch(() => props.options, val => {
      // console.log(val)
      optionsLocal.value = val
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateSearch = () => {
      optionsLocal.value = recursiveSearch(
          localSearchQuery.value,
          props.options
      )
    }

    const recursiveSearch = (
        search,
        options,
        byId = false
    ) => {
      let output = []

      // console.log(clone(options))

      options.forEach(o => {
        let childs = recursiveSearch(
            search,
            o.childs,
            byId
        )

        // if (childs.length || o._display.includes(search)) {
        if (
            childs.length ||
            (
                (byId == true && o.id == search) ||
                (byId == false && o._display.includes(search))
            )
        ) {

          if (byId == true) {
            if (o.id == search) {
              output.push({
                id: o.id,
                name: o.name,
                number: o.number,
                _display: o._display,
                childs: childs
              })
            }

          } else {
            output.push({
              id: o.id,
              name: o.name,
              number: o.number,
              _display: o._display,
              childs: childs
            })
          }

        }

      })

      return output
    }

    const recursiveSearchForOneResult = (
        search,
        options,
        byId = false
    ) => {
      let output = null

      options.forEach(o => {

        if (o.childs.length) {
          let outputTmp = recursiveSearchForOneResult(
              search,
              o.childs,
              byId
          )

          if (outputTmp != null) {
            output = outputTmp
          }
        }

        if (byId == true) {
          if (o.id == search) {
            output = {
              id: o.id,
              name: o.name,
              number: o.number,
              _display: o._display,
            }
          }
        }
      })

      return output
    }

    const selectItem = item => {
      modelLocal.value = item
      displayContent.value = false
      emit('click', item)

      populateLocalSearchQuery(item._display)
    }

    const populateLocalSearchQuery = (value = '') => {
      localSearchQuery.value = value
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    console.log(clone(modelLocal.value), modelLocal.value)
    if (modelLocal.value != null) {
      if (
          isObject(modelLocal.value) &&
          '_display' in modelLocal.value
      ) {
        populateLocalSearchQuery(modelLocal.value._display)
      } else {
        let displayContent = recursiveSearchForOneResult(
            modelLocal.value,
            optionsLocal.value,
            true
        )

        populateLocalSearchQuery(displayContent._display)


        // console.log(clone(displayContent))
        emit('update:model', displayContent)
      }
    }

    return {
      // Components

      // Data
      localSearchQuery,
      optionsLocal,
      displayContent,
      isFocus,

      // Computed
      // modelLocal,

      // Methods
      selectItem,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
<template>
  <div class="w-100">
    <div class="d-flex justify-content-between">
      <div class="text-nowrap my-auto mr-1">
        <h6 class="mb-0">{{ capitalize($t('breakdown:', { 'number': (index + 1) })) }}</h6>
      </div>

      <div v-if="isPlaintext" class="text-truncate">
        <add-button
            size="sm"
            :text="capitalize($t('addAnnexation'))"
            class="text-truncate"

            @click="$emit('addAccountingDivisionAttachment')"
        />
      </div>
    </div>
    <div class="d-flex">
      <div>
        <b-row>
          <b-col
              md="12"
              class="mb-1"
          >
            <div v-if="!isPlaintext">
              <select-group-levels
                  :options="hierarchicalAccountingLines"
                  name="accountingLine"
                  :model.sync="accountingDivisionLocal.accountingLine"
                  :is-plaintext="isPlaintext"
                  :is-required="true"
              />
            </div>
            <div v-else>
              <div>{{ capitalize($t('accountingLine')) }}</div>
              <span v-if="isObject(accountingDivisionLocal.accountingLine) && accountingDivisionLocal.accountingLine && '_display' in accountingDivisionLocal.accountingLine">
                {{ accountingDivisionLocal.accountingLine._display }}
              </span>
            </div>

          </b-col>

          <b-col md="4">
            <div v-if="!isPlaintext">
              <form-input
                  :model.sync="accountingDivisionLocal.preTaxAmount"
                  name="preTaxAmount"
                  :rules="'required'"
                  :list-index="index"
                  :is-group="!isPlaintext"
                  :display-label="isPlaintext"
                  :is-plaintext="isPlaintext"
              >
                <template #prepend>
                  <span class="text-muted">HT</span>
                </template>
                <template #append>
                  <icon icon="euro-sign"/>
                </template>
              </form-input>
            </div>
            <div v-else>
              <div>{{ capitalize($t('preTaxAmount')) }}</div>
              <span v-if="accountingDivisionLocal.preTaxAmount">
                {{ currency(accountingDivisionLocal.preTaxAmount) }}
              </span>
            </div>

          </b-col>
          <b-col md="4">
            <div v-if="!isPlaintext">
              <form-input
                  :model.sync="accountingDivisionLocal.vatAmount"
                  name="vat"
                  :rules="'required'"
                  :is-group="!isPlaintext"
                  :display-label="isPlaintext"
                  :is-plaintext="isPlaintext"
                  :list-index="index"
              >
                <template #prepend>
                  <span class="text-muted">TVA</span>
                </template>
                <template #append>
                  <icon icon="euro-sign"/>
                </template>
              </form-input>
            </div>
            <div v-else>
              <div>{{ capitalize($t('vat')) }}</div>
              <span v-if="accountingDivisionLocal.vatAmount">
                {{ currency(accountingDivisionLocal.vatAmount) }}
              </span>
            </div>
          </b-col>
          <b-col md="4">
            <div v-if="!isPlaintext">
              <form-input
                  :model.sync="accountingDivisionLocal.totalAmount"
                  name="totalAmount"
                  :rules="'required'"
                  :is-group="!isPlaintext"
                  :display-label="isPlaintext"
                  :is-plaintext="isPlaintext"
                  :list-index="index"
              >
                <template #prepend>
                  <span class="text-muted">TTC</span>
                </template>
                <template #append>
                  <icon icon="euro-sign"/>
                </template>
              </form-input>
            </div>
            <div v-else>
              <div>{{ capitalize($t('totalAmount')) }}</div>
              <span v-if="accountingDivisionLocal.totalAmount">
                {{ currency(accountingDivisionLocal.totalAmount) }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
          v-if="!isPlaintext"
          class="ml-1 my-auto"
      >
        <icon
            icon="trash-alt"
            class="cursor-pointer"
            :class="{'text-danger': isTrashHovered}"
            v-b-hover="handleHover"

            @click="$emit('remove')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import FieldSelect              from '../../input/Select2.vue'
import useAccountingLineApi     from '../../../store/accountingLine/useAccountingLineApi'
import SelectGroupLevels        from '../../input/SelectGroupLevels.vue'
import { clone, isObject }      from '../../../utils/utils'
import FormInput                from '../../input/Input.vue'
import { capitalize, currency } from '../../../utils/filter'
import AddButton                from '../../button/Add.vue'

export default {
  components: { AddButton, FormInput, SelectGroupLevels, FieldSelect },
  props: {
    accountingDivision: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 1
    },
    isPlaintext: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isTrashHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { hierarchicalAccountingLines } = useAccountingLineApi()

    const accountingDivisionLocal = computed({
      get () {
        return props.accountingDivision
      },
      set (val) {
        emit('update:accountingDivision', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(accountingDivisionLocal, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = isHovered => {
      isTrashHovered.value = isHovered
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // console.log(accountingDivisionLocal.value)
    return {
      // Components

      // Data
      isTrashHovered,

      // Computed
      accountingDivisionLocal,
      hierarchicalAccountingLines,

      // Methods
      handleHover,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { isObject, currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
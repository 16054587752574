<template>
  <div>

    <b-modal
        v-if=""
        :title="title"
        v-model="isOpenLocal"
        size="xl"
        scrollable
        hide-footer
    >
      <b-tabs>
        <template #tabs-start>
          <div class="my-auto mr-1">
            <icon
                icon="chevron-circle-left"
                :active-hover="true"
                class="cursor-pointer px-50"

                @click.native.prevent.stop="changeMonth('previous')"
            />
            <icon
                icon="chevron-circle-right"
                :active-hover="true"
                class="cursor-pointer px-50"

                @click.native.prevent.stop="changeMonth('next')"
            />
          </div>
        </template>

        <b-tab
            :active="defaultDisplay=='customersInvoices'"
            :title="capitalize($tc('customerInvoice', outgoingInvoicesContext.total_results))+ ' ('+outgoingInvoicesContext.total_results+')'"
        >
          <b-card-text>
            <customer-invoices-view3
                :indicator-start="start"
                :indicator-end="end"
            />

          </b-card-text>
        </b-tab>

        <b-tab
            :active="defaultDisplay!='customersInvoices'"
            :title="capitalize($tc('supplierInvoice', incomingInvoicesContext.total_results))+ ' ('+incomingInvoicesContext.total_results+')'"
        >
          <b-card-text>
            <!--            <suppliers-invoices-view-->
            <!--                :allow-add="false"-->
            <!--                :allow-add-by-upload="false"-->
            <!--                :allow-edit="false"-->
            <!--                :allow-remove="false"-->
            <!--                :allow-payment="false"-->

            <!--                @reloadSuppliersInvoices="getSuppliersInvoices($event)"-->
            <!--            />-->

            <suppliers-invoices-view3
                :indicator-start="start"
                :indicator-end="end"

            />

          </b-card-text>
        </b-tab>

        <!--        <b-tab :title="capitalize($t('customersInvoicesAwaitingExport'))">-->
        <!--          <b-card-text>-->
        <!--            <customer-invoices-view-->
        <!--                :allow-add="false"-->
        <!--                :allow-edit="false"-->
        <!--                :allow-remove="false"-->
        <!--                :allow-payment="false"-->
        <!--                :allow-archivate="false"-->
        <!--                :allow-validate="false"-->
        <!--                :disabled-filters="['_filter_draft', '_filter_sent']"-->
        <!--                :default-selected-filters="['_filter_isAwaitingExport']"-->

        <!--                @reloadCustomerInvoices="getCustomerInvoices($event)"-->
        <!--            />-->
        <!--          </b-card-text>-->
        <!--        </b-tab>-->

        <!--        <b-tab-->
        <!--            :title="capitalize($tc('suppliersInvoicesAwaitingExport'))">-->
        <!--          <b-card-text>-->
        <!--            <suppliers-invoices-view-->
        <!--                :allow-add="false"-->
        <!--                :allow-add-by-upload="false"-->
        <!--                :allow-edit="false"-->
        <!--                :allow-remove="false"-->
        <!--                :allow-payment="false"-->
        <!--                :default-selected-filters="['_filter_isAwaitingExport']"-->

        <!--                @reloadSuppliersInvoices="getSuppliersInvoices($event)"-->
        <!--            />-->

        <!--          </b-card-text>-->
        <!--        </b-tab>-->

        <b-tab :title="capitalize($tc('documents',2))">
          <b-card-text>
            <!--          Liaison form xlsx-->
            <document-content
                :document="{
            name: $t('LiaisonForm') + ' (Excel)',
            fileName: title,
            extension: 'xlsx'
            }"
                :isDeletable="false"
                :isParentMethodPriority="true"
                @download="downloadLiaisonForm"
                @click="downloadLiaisonForm"
            />

            <!--          Detail expenses by pdf-->
            <document-content
                :document="{
            name: $t('PurchasesMerging'),
            fileName: capitalize($t('purchases:')) + ' ' + title,
            extension: 'pdf'
            }"
                :isDeletable="false"
                :isParentMethodPriority="true"
                @download="downloadDetailPurchases"
                @click="downloadDetailPurchases"
            />

            <!--          Detail sales by pdf-->
            <document-content
                :document="{
            name: $t('SalesMerging'),
            fileName: capitalize($t('sales:')) + ' ' + title,
            extension: 'pdf'
            }"
                :isDeletable="false"
                :isParentMethodPriority="true"
                @download="downloadDetailSales"
                @click="downloadDetailSales"
            />
          </b-card-text>
        </b-tab>

      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'

// import SuppliersInvoicesView from '../../../components/views/SuppliersInvoicesView.vue'
import SuppliersInvoicesView3 from '../../../components/views/SuppliersInvoicesView3.vue'
// import CustomerInvoicesView  from '../../../components/views/CustomerInvoicesView.vue'
import CustomerInvoicesView3  from '../../../components/views/CustomerInvoicesView3.vue'
import DocumentContent        from '../../../components/card/components/DocumentContent.vue'

import moment from 'moment'
import useAPI from '../../../utils/useAPI'
import store  from '../../../store'

export default {
  components: {
    SuppliersInvoicesView3,
    CustomerInvoicesView3,
    DocumentContent
  },
  props: {
    monthStr: {
      type: String
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    defaultDisplay: {
      type: String,
      default: 'customersInvoices'
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(props.isOpen)
    const monthStrLocal = ref(props.monthStr)
    // const currentViewCustomerInvoice = ref()
    // const currentViewSupplierInvoice = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      incomingInvoices2,
      incomingInvoicesContext,
      outgoingInvoices2,
      outgoingInvoicesContext,
    } = useAPI()

    const title = computed(() => {
      return capitalize(moment(monthStrLocal.value + '-01').format('MMMM YYYY'))
    })

    const start = computed(() => {
      return moment(monthStrLocal.value + '-01').format('YYYY-MM-DD')
    })

    const end = computed(() => {
      return moment(monthStrLocal.value + '-01').endOf('month').format('YYYY-MM-DD')
    })

    const commonSupplierInvoiceSearchCriteria = computed(() => {

      // return [{
      //   sectionLogicOperator: 'AND',
      //   sectionFilters: [{
      //     logicOperator: 'AND',
      //     field: 'billingDate',
      //     operator: 'between',
      //     value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
      //   }]
      // }]

      // return [{
      //   column_name: 'billingDate',
      //   comparator: 'range',
      //   search_text_0: start.value.format('YYYY-MM-DD'),
      //   search_text_1: end.value.format('YYYY-MM-DD'),
      // }]
    })

    const commonCustomerInvoiceSearchCriteria = computed(() => {
      // console.log('A')
      // return [{
      //   sectionLogicOperator: 'AND',
      //   sectionFilters: [{
      //     logicOperator: 'AND',
      //     field: 'billingDate',
      //     operator: 'between',
      //     value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
      //   }]
      // }, {
      //   sectionLogicOperator: 'AND',
      //   sectionFilters: [{
      //     logicOperator: 'AND',
      //     field: 'billNumber',
      //     operator: 'is_not_empty',
      //   }]
      // }]

      // return [{
      //   column_name: 'billingDate',
      //   comparator: 'range',
      //   search_text_0: start.value.format('YYYY-MM-DD'),
      //   search_text_1: end.value.format('YYYY-MM-DD'),
      // }, {
      //   column_name: 'billNumber',
      //   comparator: 'notNull',
      // }]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.isOpen, val => {
      isOpenLocal.value = val
    })
    watch(isOpenLocal, val => {
      emit('update:isOpen', val)
    })
    watch(() => props.monthStr, val => {
      monthStrLocal.value = val
    })
    watch(monthStrLocal, val => {
      let start = val + '-01'
      let end = moment(val + '-01').endOf('month').format('YYYY-MM-DD')
console.log('A')
      getCustomerInvoices(start, end)
      getSuppliersInvoices(start, end)

      // console.log(val)
      // let currentViewCustomerInvoice = store.getters['moduleView/getCurrentModuleView']('customerInvoices')
      // let currentViewSupplierInvoice = store.getters['moduleView/getCurrentModuleView']('supplierInvoices')
      //
      // getSuppliersInvoices({
      //   filters: [{
      //     sectionLogicOperator: 'AND',
      //     sectionFilters: [{
      //       logicOperator: 'AND',
      //       field: 'billingDate',
      //       operator: 'between',
      //       value: [val+"-01", moment(val + '-01').endOf('month').format('YYYY-MM-DD')],
      //       _fieldDisabled: true,
      //       _sectionOperatorDisabled: true,
      //       _allowDelete: true
      //     }]
      //   }],
      //   kanbanSortBy: currentViewSupplierInvoice.kanbanSortBy,
      //   kanbanColumnAmount: currentViewSupplierInvoice.kanbanColumnAmount,
      //   listGlobalAmount: currentViewSupplierInvoice.listGlobalAmount,
      //   page: 1,
      //   sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
      // })
      //
      // getCustomerInvoices({
      //   filters: [{
      //     sectionLogicOperator: 'AND',
      //     sectionFilters: [{
      //       logicOperator: 'AND',
      //       field: 'billingDate',
      //       operator: 'between',
      //       value: [val+"-01", moment(val + '-01').endOf('month').format('YYYY-MM-DD')],
      //       _fieldDisabled: true,
      //       _sectionOperatorDisabled: true,
      //       _allowDelete: true
      //     }]
      //   }, {
      //     sectionLogicOperator: 'AND',
      //     sectionFilters: [{
      //       logicOperator: 'AND',
      //       field: 'billNumber',
      //       operator: 'is_not_empty',
      //       _fieldDisabled: true,
      //       _sectionOperatorDisabled: true,
      //       _allowDelete: true
      //     }]
      //   }],
      //   kanbanSortBy: currentViewCustomerInvoice.kanbanSortBy,
      //   kanbanColumnAmount: currentViewCustomerInvoice.kanbanColumnAmount,
      //   listGlobalAmount: currentViewCustomerInvoice.listGlobalAmount,
      //   page: 1,
      //   sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
      // })
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getSuppliersInvoices = (start, end) => {
      store.dispatch('incomingInvoice2/getIncomingInvoices', {
        filters: [{
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            field: 'billingDate',
            operator: 'between',
            value: [start, end],
            _fieldDisabled: true,
            _sectionOperatorDisabled: true,
            _allowDelete: true
          }]
        }],
        kanbanSortBy: store.getters['moduleView/getCurrentModuleView']('supplierInvoices').kanbanSortBy,
        kanbanColumnAmount: store.getters['moduleView/getCurrentModuleView']('supplierInvoices').kanbanColumnAmount,
        listGlobalAmount: store.getters['moduleView/getCurrentModuleView']('supplierInvoices').listGlobalAmount,
        page: 1,
        sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
      })
    }

    const getCustomerInvoices = (start, end) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoices', {
        filters: [{
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            field: 'billingDate',
            operator: 'between',
            value: [start, end],
            _fieldDisabled: true,
            _sectionOperatorDisabled: true,
            _allowDelete: true
          }]
        }, {
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            field: 'billNumber',
            operator: 'is_not_empty',
            _fieldDisabled: true,
            _sectionOperatorDisabled: true,
            _allowDelete: true
          }]
        }],
        kanbanSortBy: store.getters['moduleView/getCurrentModuleView']('customerInvoices').kanbanSortBy,
        kanbanColumnAmount: store.getters['moduleView/getCurrentModuleView']('customerInvoices').kanbanColumnAmount,
        listGlobalAmount: store.getters['moduleView/getCurrentModuleView']('customerInvoices').listGlobalAmount,
        page: 1,
        sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
      })
    }

    // const getSuppliersInvoices = payloadTmp => {
    //   let payload = JSON.parse(JSON.stringify(payloadTmp))
    //   // payload.filters = concatSearchCriteria(payloadTmp.filters, commonSupplierInvoiceSearchCriteria.value)
    //   store.dispatch('incomingInvoice2/getIncomingInvoices', payload)
    // }
    //
    // const getCustomerInvoices = payloadTmp => {
    //   console.log(payloadTmp)
    //   let payload = JSON.parse(JSON.stringify(payloadTmp))
    //   // payload.filters = concatSearchCriteria(payloadTmp.filters, commonCustomerInvoiceSearchCriteria.value)
    //   store.dispatch('outgoingInvoice2/getOutgoingInvoices', payload)
    // }

    // const concatSearchCriteria = (criterias1, criterias2) => {
    //   let criterias = JSON.parse(JSON.stringify(criterias1))
    //
    //   JSON.parse(JSON.stringify(criterias2)).forEach(c2 => {
    //     let pos = criterias1.findIndex(c1 => c1.column_name == c2.column_name)
    //
    //     if (pos != -1) {
    //       criterias[pos] = c2
    //     } else {
    //       criterias.push(c2)
    //     }
    //   })
    //   return criterias
    // }

    const changeMonth = (previousOrNext) => {
      if (previousOrNext == 'previous') {
        monthStrLocal.value = moment(monthStrLocal.value + '-01').subtract(1, 'months').format('YYYY-MM')
      } else {
        monthStrLocal.value = moment(monthStrLocal.value + '-01').add(1, 'months').format('YYYY-MM')
      }
    }

    const downloadDetailPurchases = (type) => {
      // console.log(start.value)
      // console.log(moment(start.value).format('YYYY-MM'))
      if (type == 'excel') {
        // store.dispatch('document/generateExcelDetailRevenue', start.value.format('YYYY-MM'))
        store.dispatch('document/generateExcelDetailRevenue', moment(start.value).format('YYYY-MM'))
      } else {
        // store.dispatch('document/generatePdfDetailPurchases', start.value.format('YYYY-MM'))
        store.dispatch('document/generatePdfDetailPurchases', moment(start.value).format('YYYY-MM'))
      }
    }
    const downloadDetailSales = () => {
      // store.dispatch('document/generatePdfDetailSales', start.value.format('YYYY-MM'))
      store.dispatch('document/generatePdfDetailSales', moment(start.value).format('YYYY-MM'))
    }

    const downloadLiaisonForm = (type = 'xlsx') => {
      // console.log(start.value)
      // console.log(start.value.format('YYYY-MM-DD'))
      store.dispatch('document/generateLiaisonForm', { date: start.value.format('YYYY-MM'), type: type })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // store.dispatch('moduleView/getModuleViews')
    //     .then((response) => {
    //       currentViewCustomerInvoice.value = store.getters['moduleView/getCurrentModuleView']('customerInvoices')
    //       currentViewSupplierInvoice.value = store.getters['moduleView/getCurrentModuleView']('supplierInvoices')
    //
    //       getCustomerInvoices(start.value, end.value)
    //       getSuppliersInvoices(start.value, end.value)
    //     })

    // store.getters['moduleView/getCurrentModuleView']('customerInvoices')
    //     .then(() => {
    //       getCustomerInvoices(start, end)

    // store.getters['moduleView/getCurrentModuleView']('supplierInvoices')
    //     .then(() => {
    //       getSuppliersInvoices(start, end)
    // })
    // })

    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      start,
      end,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,
      outgoingInvoices2,
      outgoingInvoicesContext,
      title,

      // Methods
      getSuppliersInvoices,
      getCustomerInvoices,
      changeMonth,
      downloadDetailPurchases,
      downloadDetailSales,
      downloadLiaisonForm,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <vue-apex-charts
      type="bar"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, number } from '@/utils/filter'

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
// import i18n from '@/libs/i18n'

export default {
  components: {
    VueApexCharts
  },
  props: {
    series: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    isXDate: {
      type: Boolean,
      default: true
    },
    isCurrencies: {
      type: Boolean,
      default: false
    },
    displays: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: () => []
    },
    withLegend: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // console.log(props.series)
    // console.log(props.labels)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const chartOptions = computed(() => {
      let output = {
        chart: {
          toolbar: { show: false },
          type: 'bar',
          sparkline: {
            enabled: !props.displays
          },
          events: {
            click: function (event, chartContext, config) {
              // console.log(config)
              // console.log('Date : ', props.labels[config.dataPointIndex])
              // props.series.forEach(s => {
              //   console.log('Serie : ', s.data[config.dataPointIndex])
              // })

              if (config.dataPointIndex != -1) {
                emit('click', {
                  position: config.dataPointIndex,
                  label: props.labels[config.dataPointIndex]
                })
              }
            },
          }
        },
        legend: {
          show: props.withLegend,
        },
        grid: {
          show: props.displays,
          padding: {
            left: 0,
            right: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: props.labels,
          labels: {
            formatter (val) {
              if (val != null) {
                if (props.isXDate) {
                  return capitalize(moment(val).format("MMMM YYYY"))
                } else {
                  return val
                }
              }
            },
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              if (props.displays) {
                if (props.isCurrencies) {
                  return currency(val, true)
                } else {
                  return number(val)
                }
              }
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (props.isCurrencies) {
                return currency(val, true)
              } else {
                return number(val)
              }
            }
          }
        }
      }

      if (props.colors.length) {
        output.colors = props.colors
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="xs"
      scrollable
      hide-footer
  >
    <slot name="top" />

    <field-file
        :singleFile.sync="singleFileLocal"
        :documentType="documentType"
        :accepted-types="acceptedTypes"

        @delete="$emit('update:singleFile', null)"
    />
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import FieldFile from '../input/File'

export default {
  components: {
    FieldFile
  },
  props: {
    singleFile: {
      type: Object,
      default: () => {}
    },
    documentType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    acceptedTypes: {
      type: Array,
      default: () => ['pdf', 'image']
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const singleFileLocal = ref(props.singleFile)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })

    watch(singleFileLocal, (val) => {
      emit('update:singleFile', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetUpload = () => {
      singleFileLocal.value = JSON.parse(JSON.stringify(null))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      singleFileLocal,

      // Computed

      // Methods
      resetUpload,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    isOpen: function (val) {
      this.isOpenLocal = val
    },
    singleFile: function (val) {
      this.singleFileLocal = val
    }
  },
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <div
      v-b-hover="hoverHandler"
      style="padding-top: 0.5rem; padding-bottom: 0.5rem"
      class="cursor-pointer rounded-lg px-1 text-truncate"
      :class="{'bg-primary bg-lighten-5':isHover}"

      @click="$emit('click', item)"
  >
    {{ item._display}}
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const hoverHandler = isHovered => {
      isHover.value = isHovered
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHover,

      // Computed

      // Methods
      hoverHandler,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
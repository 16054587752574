<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('customerInvoice',2)"
        :items="outgoingInvoices2"
        :items-context="outgoingInvoicesContext"
        :available-filters="store.getters['outgoingInvoice2/getAvailableFilters']"
        :available-global-amounts="store.getters['outgoingInvoice2/getAvailableGlobalAmounts']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="$can('manage', 'customerInvoices')"
        :is-view-windows-ready="displayedCustomerInvoice!=null"

        @addItem="customerInvoiceModalShow=true"
        @showDetails="showDetails"
        @resetDisplayedViewWindow="displayedCustomerInvoice=null"
    >
      <template #listView_cell__reconciliationStatus="{item}">
        <b-badge
            v-if="item._reconciliationStatus != null"
            pill
            :variant="getBadgeColorReconciliationStatus(item)"
        >
          {{ $t(item._reconciliationStatus) }}
          <!--          <span v-if="item._reconciliationStatus == 'Unlettered'">-->
          <!--            {{-->
          <!--              $tc('Unlettered:',-->
          <!--                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),-->
          <!--                  {-->
          <!--                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),-->
          <!--                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')-->
          <!--                  }-->
          <!--              )-->
          <!--            }}-->
          <!--          </span>-->
          <!--          <span v-else>-->
          <!--            {{ $t(item._reconciliationStatus) }}-->
          <!--          </span>-->
        </b-badge>
      </template>
      <template #listView_cell__bankReconciliationStatus="{item}">
        <b-badge
            v-if="item._bankReconciliationStatus != null"
            pill
            :variant="getBadgeColorBankReconciliationStatus(item)"
        >
          {{ getBankReconciliationStatus(item) }}
          <!--          <span v-if="item._bankReconciliationStatus == 'Unpaid'">-->
          <!--            {{capitalize($t('unBankReconciliated'))}}-->
          <!--&lt;!&ndash;            {{
            &ndash;&gt;-- >
            < !-- &lt; ! & ndash;              $tc('Unpaid:', & ndash;&gt;-- >
            < !-- &lt; ! & ndash;                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),&ndash;&gt;-- >
            < !-- &lt; ! & ndash;                  { & ndash;&gt;-- >
            < !-- &lt; ! & ndash;                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),&ndash;&gt;-- >
            < !-- &lt; ! & ndash;                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate') & ndash;&gt;-- >
            < !-- &lt; ! & ndash;                  }&ndash;&gt;-- >
            < !-- &lt; ! & ndash;              )&ndash;&gt;-- >
            < !-- &lt; ! & ndash;
          }}&ndash;&gt;-->
          <!--          </span>-->
          <!--          <span v-else>-->
          <!--            {{capitalize($t('bankReconciliated'))}}-->
          <!--&lt;!&ndash;            {{ $t(item._bankReconciliationStatus) }}&ndash;&gt;-->
          <!--          </span>-->
        </b-badge>
      </template>
      <template #listView_cell_export="{item}">
        <span
            v-if="item.export != null"
            @click.stop="downloadByDocument(item.export.document)"
        >{{ item.export.date|moment('MMMM YYYY')|capitalize }}</span>
        <span v-else>-</span>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Download -->
        <button-download
            @click.native.stop="downloadOutgoingInvoice(item)"
            v-b-tooltip.hover.left="$t('Download')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delivery -->
        <delivery-button
            @click.native.stop="downloadDeliveryNote(item)"
            v-b-tooltip.hover.left="item._isDeliverable?$t('DeliveryNote'):$t('noDeliverableArticles')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="!item._isDeliverable"
            size="sm"
        />

        <!--          Validate -->
        <button-validate
            v-if="item.billNumber == null && $can('manage', 'CustomerInvoices')"
            @click.native.stop="validateCustomerInvoiceLocal(item)"
            v-b-tooltip.hover.left="$t('validate')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Edit -->
        <button-edit
            v-if="$can('manage', 'CustomerInvoices')"
            @click.native.stop="editCustomerInvoiceLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="item.billNumber != null"
            size="sm"
        />

        <!--          Duplicate-->
        <button-duplicate
            v-if="$can('manage', 'CustomerInvoices')"
            v-b-tooltip.hover.left="capitalize($t('duplicate'))"
            @click.native.stop="duplicateCustomerInvoice(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-if="$can('manage', 'CustomerInvoices')"
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteCustomerInvoiceAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="item.billNumber != null"
            size="sm"
        />
      </template>


      <template #leftViewWindow="{item}">
        <customerInvoice-card
            :outgoing-invoice="item"
            :is-viewable="true"
            :is-selected="item.id==displayedCustomerInvoice.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedCustomerInvoice.billNumber }}
      </template>
      <template #viewWindowButtons>
        <!--          Download -->
        <button-download
            @click.native.stop="downloadOutgoingInvoice(displayedCustomerInvoice)"
            v-b-tooltip.hover.left="$t('Download')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delivery -->
        <delivery-button
            @click.native.stop="downloadDeliveryNote(displayedCustomerInvoice)"
            v-b-tooltip.hover.left="displayedCustomerInvoice._isDeliverable?$t('DeliveryNote'):$t('noDeliverableArticles')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="!displayedCustomerInvoice._isDeliverable"
            size="sm"
        />

        <!--          Validate -->
        <button-validate
            v-if="displayedCustomerInvoice.billNumber == null && $can('manage', 'CustomerInvoices')"
            @click.native.stop="validateCustomerInvoiceLocal(displayedCustomerInvoice)"
            v-b-tooltip.hover.left="$t('validate')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Duplicate-->
        <button-duplicate
            v-if="$can('manage', 'CustomerInvoices')"
            v-b-tooltip.hover.left="capitalize($t('duplicate'))"
            @click.native.stop="duplicateCustomerInvoice(displayedCustomerInvoice)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Edit -->
        <button-edit
            v-if="$can('manage', 'CustomerInvoices')"
            @click.native.stop="editCustomerInvoiceLocal(displayedCustomerInvoice)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="displayedCustomerInvoice.billNumber != null"
            size="sm"
        />
      </template>
      <template #windowView>
        <customer-invoice-view :customer-invoice="displayedCustomerInvoice"/>
      </template>

    </base-template>

    <!-- modal customer invoice -->
    <modal-outgoing-invoice
        :outgoing-invoice.sync="currentCustomerInvoice"
        :title="('id' in currentCustomerInvoice)?$t('EditInvoice'):$t('NewInvoice')"
        :is-open.sync="customerInvoiceModalShow"
        @submitValidatedOutgoingInvoice="submitValidatedCustomerInvoiceLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useCustomerInvoices }          from '../../views/sales/customerInvoice/useCustomerInvoice'
import { downloadByDocument, getRoute } from '../../utils/utils'
import { capitalize }                   from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import store  from '../../store'
import moment from 'moment'

import BaseTemplate         from '../base3/Base.vue'
import ButtonRemove         from '../button/Remove.vue'
import ButtonValidate       from '../button/Validate.vue'
import ButtonEdit           from '../button/Edit.vue'
import ButtonDownload       from '../button/Download.vue'
import CustomerInvoiceView  from './CustomerInvoiceView.vue'
import CustomerInvoiceCard  from '../card/OutgoingInvoice2.vue'
import ModalOutgoingInvoice from '../prompt/OutgoingInvoice.vue'
import ButtonDuplicate      from '../button/Duplicate.vue'
import i18n                 from '../../libs/i18n'
import DeliveryButton       from '../button/DeliveryButton.vue'

export default {
  components: {
    DeliveryButton,
    ButtonDuplicate,
    ModalOutgoingInvoice,
    CustomerInvoiceCard,
    CustomerInvoiceView,
    ButtonDownload,
    ButtonEdit,
    ButtonValidate,
    ButtonRemove,
    BaseTemplate
  },
  props: {
    indicatorStart: {
      type: String,
    },
    indicatorEnd: {
      type: String,
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('customerInvoices')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedCustomerInvoice = ref(null)
    const currentCustomerInvoice = ref(null)
    const customerInvoiceModalShow = ref(false)
    const customerInvoiceByUploadModalShow = ref(false)
    const newCustomerInvoiceFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { outgoingInvoices2, outgoingInvoicesContext, autocompleteWorkFlos } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      if (getRoute().name == 'WorkFlo view') {
        emit('updatePayload', val)
      } else {
        fetchOutgoingInvoices2(val)
            .then(() => {
              if (!isAutocompleteLoaded.value) {
                loadInit()
              }
            })
      }
    }, { deep: true })

    watch(customerInvoiceModalShow, val => {
      if (val == false) {
        resetCustomerInvoice()
      }
    })

    watch(autocompleteWorkFlos, val => {
      resetCustomerInvoice()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOutgoingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
      fetchAutocompleteContacts,
      fetchAutocompleteArticles,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteWorkflows,
      fetchAutocompleteVats
    } = useAPI()

    const {
      submitValidatedOutgoingInvoice,
      removeOutgoingInvoice,
      downloadDeliveryNote,
      downloadOutgoingInvoice,
      validateOutgoingInvoice
    } = useCustomerInvoices()

    const loadInit = () => {
      fetchAutocompletePaymentMethods()
      fetchAutocompleteBankAccounts()
      fetchAutocompleteContacts()
      fetchAutocompleteArticles()
      fetchAutocompletePaymentTerms()
      fetchAutocompleteWorkFlos()
      fetchAutocompleteWorkflows()
      fetchAutocompleteVats()
      isAutocompleteLoaded.value = true
    }

    const showDetails = (customerInvoice) => {
      if (customerInvoice != null) {
        store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
            .then(response => {
              displayedCustomerInvoice.value = response
            })

      } else {
        resetCustomerInvoice()
      }
    }

    const resetCustomerInvoice = () => {
      currentCustomerInvoice.value = JSON.parse(JSON.stringify(store.getters['outgoingInvoice2/getEmptyOutgoingInvoice']))

      if (getRoute().name == 'WorkFlo view') {
        if (!autocompleteWorkFlos.value.length) {
          currentCustomerInvoice.value.workFlo = {
            id: parseInt(getRoute().params.workFloId)
          }
        } else {
          currentCustomerInvoice.value.workFlo = store.getters['workFlo/getAutocompleteWorkFlos'].find(w => w.id == parseInt(getRoute().params.workFloId))
        }
      }
    }

    const validateCustomerInvoiceLocal = (customerInvoice) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
          .then(response => {
            validateOutgoingInvoice(response)
                .then(response => {
                  if (response.data.workFlo != null) {
                    let workFlo = store.getters['workFlo/getWorkFlo'](response.data.workFlo.id)

                    // console.log(workFlo)
                    workFlo.status = {
                      id: 7,
                      name: 'Suivi'
                    }
                    // store.commit('workFlo/UPDATE_WORKFLO', workFlo)
                  }

                  baseTemplate.value.closeViewWindow()
                })
          })
    }

    const editCustomerInvoiceLocal = (customerInvoice) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
          .then(response => {
            currentCustomerInvoice.value = response
            customerInvoiceModalShow.value = true
            baseTemplate.value.closeViewWindow()
          })
    }

    const removeCustomerInvoiceLocal = (customerInvoice) => {
      removeOutgoingInvoice(customerInvoice)
          .then(response => {

          })
    }

    const submitValidatedCustomerInvoiceLocal = () => {
      // console.log(currentCustomerInvoice.value)
      submitValidatedOutgoingInvoice(currentCustomerInvoice.value)
          .then(response => {
            resetCustomerInvoice()
            customerInvoiceModalShow.value = false
          })
    }

    const getBadgeColorReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Lettered') {
        return 'success'
      } else if (item._reconciliationStatus == 'Partially lettered') {
        return 'warning'
      } else if (item._reconciliationStatus == 'Unlettered') {
        return 'danger'
      }
    }

    const getBadgeColorBankReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Open') {
        return 'secondary'
      } else {
        if (item._bankReconciliationStatus == 'Paid') {
          return 'success'
        } else if (item._bankReconciliationStatus == 'Partially paid') {
          return 'warning'
        } else if (item._bankReconciliationStatus == 'Unpaid') {
          return 'danger'
        }
      }
    }

    const getDaysRemaining = (dueDate) => {
      return parseInt(moment.duration(moment(dueDate).diff(moment().startOf('day'))).asDays())
    }

    const duplicateCustomerInvoice = (customerInvoice) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
          .then(customerInvoice => {
            currentCustomerInvoice.value = customerInvoice
            // populateWorkflow(currentCustomerInvoice.value)
            delete currentCustomerInvoice.value.bankOperations
            delete currentCustomerInvoice.value.customerPurchaseOrder
            delete currentCustomerInvoice.value.customerPurchaseOrderDate
            delete currentCustomerInvoice.value.customerPurchaseOrderNumber
            currentCustomerInvoice.value.isArchived = false
            currentCustomerInvoice.value.lastExport = null
            currentCustomerInvoice.value.paymentRelations = []

            let products = []
            // currentCustomerInvoice.value.products = []
            currentCustomerInvoice.value.products.forEach(p => {
              delete p.id
              delete p.parent
              products.push(p)
            })
            currentCustomerInvoice.value.products = products

            delete currentCustomerInvoice.value.billNumber
            delete currentCustomerInvoice.value.customerPurchaseOrderNumber
            delete currentCustomerInvoice.value.customerPurchaseOrderDate
            delete currentCustomerInvoice.value.customerPurchaseOrder
            delete currentCustomerInvoice.value.id
            delete currentCustomerInvoice.value.logs

            delete currentCustomerInvoice.value._balance
            delete currentCustomerInvoice.value._linkedOffer

            customerInvoiceModalShow.value = true
          })
    }

    const getBankReconciliationStatus = (item) => {
      if (item._bankReconciliationStatus == 'Unpaid') {
        if (moment() > moment(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))) {
          return i18n.tc('Unpaid:',
              getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
              {
                days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? i18n.t('remaining') : i18n.t('ofLate')
              }
          )
        } else {
          return i18n.t(item._bankReconciliationStatus)
        }
      } else {
        return i18n.t(item._bankReconciliationStatus)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCustomerInvoice()

    if (getRoute().name != 'WorkFlo view') {
      if (getRoute().name == 'Indicators') {
        store.dispatch('moduleView/getModuleViews')
            .then((response) => {
              let currentView = store.getters['moduleView/getCurrentModuleView']('customerInvoices')
              // let currentYear = parseInt(new Date().getFullYear()) - 1
              // let start = moment(currentYear + '-07-01').format('YYYY-MM-DD')
              // let end = moment((currentYear + 1) + '-06-30').format('YYYY-MM-DD')

              console.log(props.indicatorStart)
              console.log(props.indicatorEnd)

              payload.value = {
                filters: [{
                  sectionLogicOperator: 'AND',
                  sectionFilters: [{
                    logicOperator: 'AND',
                    field: 'billingDate',
                    operator: 'between',
                    value: [props.indicatorStart, props.indicatorEnd],
                    _fieldDisabled: true,
                    _sectionOperatorDisabled: true,
                    _allowDelete: true
                  }]
                }, {
                  sectionLogicOperator: 'AND',
                  sectionFilters: [{
                    logicOperator: 'AND',
                    field: 'billNumber',
                    operator: 'is_not_empty',
                    _fieldDisabled: true,
                    _sectionOperatorDisabled: true,
                    _allowDelete: true
                  }]
                }],
                kanbanSortBy: currentView.kanbanSortBy,
                kanbanColumnAmount: currentView.kanbanColumnAmount,
                listGlobalAmount: currentView.listGlobalAmount,
                page: 1,
                sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
              }

            })

      } else {
        store.dispatch('moduleView/getModuleViews')
            .then((response) => {
              let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

              payload.value = {
                filters: currentView.filters,
                kanbanSortBy: currentView.kanbanSortBy,
                kanbanColumnAmount: currentView.kanbanColumnAmount,
                listGlobalAmount: currentView.listGlobalAmount,
                page: 1,
                sorts: currentView.sorts,
              }

            })
      }
    } else {
      let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

      payload.value = {
        filters: currentView.filters,
        kanbanSortBy: currentView.kanbanSortBy,
        kanbanColumnAmount: currentView.kanbanColumnAmount,
        listGlobalAmount: currentView.listGlobalAmount,
        page: 1,
        sorts: currentView.sorts,
      }
    }

    return {
      // Components
      capitalize,

      // Data
      module,
      payload,
      isReady,

      displayedCustomerInvoice,
      currentCustomerInvoice,
      customerInvoiceModalShow,
      customerInvoiceByUploadModalShow,
      newCustomerInvoiceFile,

      // Computed
      outgoingInvoices2,
      outgoingInvoicesContext,

      // Methods
      showDetails,
      validateCustomerInvoiceLocal,
      editCustomerInvoiceLocal,
      removeCustomerInvoiceLocal,
      submitValidatedCustomerInvoiceLocal,
      getBadgeColorReconciliationStatus,
      getBadgeColorBankReconciliationStatus,
      getDaysRemaining,
      downloadOutgoingInvoice,
      downloadDeliveryNote,
      duplicateCustomerInvoice,
      getBankReconciliationStatus,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {
    downloadByDocument,
    deleteCustomerInvoiceAlert (customerInvoice) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theCustomerInvoice') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeCustomerInvoiceLocal(customerInvoice)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
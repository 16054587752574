var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter',{attrs:{"items":_vm.items,"isActionColumnEnabled":true,"itemView":"list","sortOptions":_vm.sortOptions,"columns":_vm.columns,"show-past-years-filter":true,"readOnly":true},on:{"columnClick":false},scopedSlots:_vm._u([{key:"resultInfos",fn:function(ref){
var items = ref.items;
return [_vm._v(" ("+_vm._s(items.length)+") "),_c('span',[_vm._v(_vm._s(_vm.currency(_vm.getPreTaxAmounts(items)))+" HT / "+_vm._s(_vm.currency(_vm.getTotalAmounts(items)))+" TTC")])]}},{key:"listView_cell_type",fn:function(ref){
var item = ref.item;
return [_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(item.type=='toll'?_vm.$tc('transport'):(item.type=='default'?_vm.$tc('generalExpenses'):_vm.$tc(item.type)))),expression:"capitalize(item.type=='toll'?$tc('transport'):(item.type=='default'?$tc('generalExpenses'):$tc(item.type)))",modifiers:{"hover":true,"left":true}}],attrs:{"icon":_vm.getIncomingInvoiceIconType(item).icon,"library":_vm.getIncomingInvoiceIconType(item).library?_vm.getIncomingInvoiceIconType(item).library:'fas'}})]}},{key:"listView_cell_supplier",fn:function(ref){
var item = ref.item;
return [(item.supplier != null)?_c('span',[_vm._v(_vm._s(_vm.capitalize(_vm.$tc(item.supplier._display))))]):_vm._e()]}},{key:"listView_cell_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,'L'))+" ")]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('Download'))),expression:"capitalize($t('Download'))",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointe mx-1",attrs:{"icon":"download"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadItem(item)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>

    <div v-if="incomingInvoiceLocal.accountingDivisions.length == 0">
      <i>{{ capitalize($t('noAccountingDivision')) }}</i>
    </div>
    <div v-else>
      <div
          v-for="(accountingDivision, index) in incomingInvoiceLocal.accountingDivisions"
          :key="'incomingInvoice_accountingDivision_'+index"
          class="mb-1"
      >
        <b-row>
          <b-col md="5">
            <div class="h-100 d-flex justify-content-center">
              <form-accounting-division
                  :accounting-division.sync="accountingDivision"
                  :index="index"
                  :is-plaintext="true"
                  class="my-auto mb-1"

                  @addAccountingDivisionAttachment="addAccountingDivisionAttachment(index)"
              />
            </div>
          </b-col>

          <b-col md="7">
            <div class="d-flex overflow-auto">
              <form-accounting-division-attachment
                  v-for="(accountingDivisionAttachment, indexAttachment) in accountingDivision.accountingDivisionAttachments"
                  :key="'incomingInvoice_accountingDivision_'+index+'_attachment'+indexAttachment"
                  :index="indexAttachment"
                  :accounting-division-attachment.sync="accountingDivisionAttachment"
                  class="mx-1"
                  style="min-width: 200px;"

                  @remove="removeAccountingDivisionAttachment(index, indexAttachment)"
              />
            </div>


<!--            <add-button-->
<!--                size="sm"-->
<!--                :text="capitalize($t('addAnnexation'))"-->

<!--                @click="addAccountingDivisionAttachment(index)"-->
<!--            />-->
          </b-col>
        </b-row>

        <hr class="w-100"/>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch }         from '@vue/composition-api'
import { capitalize }                   from '../../../../utils/filter'
import FormAccountingDivision           from '../../accountingDivision/FormAccountingDivision.vue'
import FormAccountingDivisionAttachment from '../../accountingDivision/FormAccountingDivisionAttachment.vue'
import AddButton                        from '../../../button/Add.vue'

export default {
  components: { AddButton, FormAccountingDivisionAttachment, FormAccountingDivision },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const incomingInvoiceLocal = computed({
      get () {
        return props.incomingInvoice
      },
      set (val) {
        emit('update:incomingInvoice', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addAccountingDivisionAttachment = accountingDivisionIndex => {
      incomingInvoiceLocal.value.accountingDivisions[accountingDivisionIndex].accountingDivisionAttachments.push({})
    }

    const removeAccountingDivisionAttachment = (accountingDivisionIndex, accountingDivisionAttachmentIndex) => {
      incomingInvoiceLocal.value.accountingDivisions[accountingDivisionIndex].accountingDivisionAttachments.splice(accountingDivisionAttachmentIndex, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      incomingInvoiceLocal,

      // Methods
      addAccountingDivisionAttachment,
      removeAccountingDivisionAttachment,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
<template>
  <div>

    <div class="d-flex">
      <div>
        <!--    Workflows -->
        <form-workflows
            v-show="isWorkflowViewed"
            :entity.sync="accountingDivisionAttachmentLocal"
            :class="{'mb-1': !isWorkFloViewed}"

            @input="sourceUpdated('workflow')"
        />

        <!--    WorkFlo-->
        <form-select
            v-show="isWorkFloViewed"
            :model.sync="accountingDivisionAttachmentLocal.workFlo"
            :options="autocompleteWorkFlos"
            label="_display"
            name="workFlo"
            class="mb-1"
            ref="workFloSelect"

            @input="sourceUpdated('workFlo')"
        />

        <!--    Department-->
        <select-group-levels
            class="mb-1"
            :options="hierarchicalDepartments"
            :model.sync="accountingDivisionAttachmentLocal.department"
            :index="index"
            :is-required="true"
            placeholder="department"
        />

        <!--    Department attribution-->
        <form-input
            :model.sync="accountingDivisionAttachmentLocal.awarding"
            name="awarding"
            :rules="'required|decimal|min_value:0|max_value:100'"
            :is-group="true"
            :display-label="false"
            :list-index="index"
        >
          <template #prepend>
            <span class="text-muted">{{ capitalize($t('awarding')) }}</span>
          </template>
          <template #append>
            <icon icon="percent"/>
          </template>
        </form-input>
      </div>

      <div class="ml-1 my-auto">
        <icon
            icon="trash-alt"
            class="cursor-pointer"
            :class="{'text-danger': isTrashHovered}"
            v-b-hover="handleHover"

            @click="$emit('remove')"
        />
      </div>
    </div>





  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import SelectGroupLevels        from '../../input/SelectGroupLevels.vue'
import useDepartmentApi         from '../../../store/department/useDepartmentApi'
import FormInput                from '../../input/Input.vue'
import { capitalize }           from '../../../utils/filter'
import FormWorkflows            from '../../input/Workflow.vue'
import FormSelect               from '../../input/Select.vue'
import useAPI                   from '../../../utils/useAPI'
import { clone }                from '../../../utils/utils'

export default {
  components: { FormSelect, FormWorkflows, FormInput, SelectGroupLevels },
  props: {
    accountingDivisionAttachment: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 1
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isTrashHovered = ref(false)
    const workFloSelect = ref()
    const isWorkflowViewed = ref(true)
    const isWorkFloViewed = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { hierarchicalDepartments } = useDepartmentApi()
    const { autocompleteWorkFlos } = useAPI()

    const accountingDivisionAttachmentLocal = computed({
      get () {
        return props.accountingDivisionAttachment
      },
      set (val) {
        emit('update:accountingDivisionAttachment', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = isHovered => {
      isTrashHovered.value = isHovered
    }

    const sourceUpdated = source => {
      console.log(source)
      if (source == "workflow") {
        if (accountingDivisionAttachmentLocal.value.workflow == null) {
          if (accountingDivisionAttachmentLocal.value.workFlo == null) {
            isWorkflowViewed.value = true
            isWorkFloViewed.value = true
          }
        } else {
          workFloSelect.value.updateValue(null)
          isWorkFloViewed.value = false
        }
      } else {
        if (accountingDivisionAttachmentLocal.value.workFlo == null) {
          if (accountingDivisionAttachmentLocal.value.workflow == null) {
            isWorkflowViewed.value = true
            isWorkFloViewed.value = true
          }
        } else {
          isWorkflowViewed.value = false
          accountingDivisionAttachmentLocal.value.workflow = null
          accountingDivisionAttachmentLocal.value.phase = null
        }
      }

      console.log(clone(accountingDivisionAttachmentLocal.value))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    console.log(clone(accountingDivisionAttachmentLocal.value))
    return {
      // Components

      // Data
      isTrashHovered,
      workFloSelect,
      isWorkflowViewed,
      isWorkFloViewed,

      // Computed
      hierarchicalDepartments,
      accountingDivisionAttachmentLocal,
      autocompleteWorkFlos,

      // Methods
      handleHover,
      sourceUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
<template>
  <div>
    <workflow
        :entity.sync="incomingInvoice"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject }             from '../../../../utils/utils'

import FieldSelect from '../../../input/Select2.vue'
import useAPI      from '../../../../utils/useAPI'
import Workflow    from '../../../input/Workflow.vue'

export default {
  components: {
    Workflow,
    FieldSelect
  },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkflows } = useAPI()

    const availablePhases = computed(() => {
      if (isObject(props.incomingInvoice.workflow)) {
        return props.incomingInvoice.workflow.phases

        // return workflows.value.find(w => w.id == props.incomingInvoice.workflow.id).phases
        // } else if (workflows.value.some(w => w.id == props.incomingInvoice.workflow)) {
        //   return workflows.value.find(w => w.id == props.incomingInvoice.workflow).phases
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetIncomingInvoicePhase = () => {
      props.incomingInvoice.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      autocompleteWorkflows,
      availablePhases,

      // Methods
      resetIncomingInvoicePhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-0">
        <b-card-title>{{ capitalize($tc('revenue',2)) }}</b-card-title>
<!--        <b-dropdown-->
<!--            no-caret-->
<!--            right-->
<!--            text="Last 7 Days"-->
<!--            variant="transparent"-->
<!--            class="chart-dropdown"-->
<!--            toggle-class="p-50"-->
<!--            size="sm"-->
<!--        >-->
<!--          <b-dropdown-item-->
<!--              v-for="day in trackerData.lastDays"-->
<!--              :key="day"-->
<!--          >-->
<!--            {{ day }}-->
<!--          </b-dropdown-item>-->
<!--        </b-dropdown>-->
      </b-card-header>

      <b-card-body>
        <chart-columns
            :labels="xAxisLabels"
            :series="series"
            @click="revueSelected"
            :isCurrencies="true"
        />
      </b-card-body>
    </b-card>

    <!--    Detail modal-->
    <prompt-detail-revenue
        :date="modalTitle"
        :isOpen.sync="isModalDetailOpen"
        :elementsToDetail="elementsToDetail"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { useIndicators } from '../useIndicators'

import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
import moment from 'moment'
import ChartColumns from '../../chart/Columns'
import PromptDetailRevenue from '../components/_promptDetailRevenue'

export default {
  components: {
    ChartColumns,
    PromptDetailRevenue
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isModalDetailOpen = ref(false)
    const modalTitle = ref(null)
    const elementsToDetail = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentTurnoverByFinancialYear = computed(() => {
      return getTurnoverByFinancialYear(offers.value, outgoingInvoices.value)
    })

    const currentPurchasesByFinancialYear = computed(() => {
      return getPurchasesByFinancialYear(orders.value, incomingInvoices.value, events.value)
    })

    const xAxisLabels = computed(() => {
      let output = []
      currentTurnoverByFinancialYear.value.forEach(item => output.push(capitalize(moment(item.month).format('MMMM YYYY'))))

      return output
    })

    const inSerie = computed(() => {
      let output = []
      currentTurnoverByFinancialYear.value.forEach(item => output.push(item.turnover.total))

      return output
    })

    const outSerie = computed(() => {
      let output = []
      currentPurchasesByFinancialYear.value.forEach(item => output.push(item.purchases.total))
      return output
    })

    const series = computed(() => {
      return [
        {
          name: capitalize(i18n.t('incoming')),
          data: inSerie.value
        },
        {
          name: capitalize(i18n.t('outgoing')),
          data: outSerie.value
        },
      ]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOffers,
      offers,
      fetchOutgoingInvoices,
      outgoingInvoices,
      fetchOrders,
      orders,
      fetchIncomingInvoices,
      incomingInvoices,
      fetchEvents,
      events
    } = useAPI()

    const { getTurnoverByFinancialYear, getPurchasesByFinancialYear } = useIndicators()

    const revueSelected = (index) => {
      // console.log(index)
      modalTitle.value = currentTurnoverByFinancialYear.value[index].month

      // console.log(currentTurnoverByFinancialYear.value)

      elementsToDetail.value = {
        offers: currentTurnoverByFinancialYear.value[index].turnover.offerEntities,
        outgoingInvoices: currentTurnoverByFinancialYear.value[index].turnover.outgoingInvoiceEntities,
        orders: currentPurchasesByFinancialYear.value[index].purchases.orderEntities,
        incomingInvoices: currentPurchasesByFinancialYear.value[index].purchases.incomingInvoiceEntities,
      }

      isModalDetailOpen.value = true
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchOffers()
    fetchOutgoingInvoices()
    fetchOrders()
    fetchIncomingInvoices()
    fetchEvents()

    return {
      // Components
      capitalize,

      // Data
      modalTitle,
      isModalDetailOpen,
      elementsToDetail,

      // Computed
      currentTurnoverByFinancialYear,
      currentPurchasesByFinancialYear,
      xAxisLabels,
      series,

      // Methods
      revueSelected,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
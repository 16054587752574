<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('supplierInvoice',2)"
        :items="incomingInvoices2"
        :items-context="incomingInvoicesContext"
        :available-filters="store.getters['incomingInvoice2/getAvailableFilters']"
        :available-global-amounts="store.getters['incomingInvoice2/getAvailableGlobalAmounts']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-add-by-upload="true"
        :allow-view-windows="$can('manage', 'supplierInvoices')"
        :is-view-windows-ready="displayedSupplierInvoice!=null"

        @addItem="supplierInvoiceModalShow=true"
        @addItemByUpload="supplierInvoiceByUploadModalShow = true"
        @showDetails="showDetails"
        @resetDisplayedViewWindow="displayedSupplierInvoice=null"
    >
      <template #listView_cell_type="{item}">
        <icon
            v-b-tooltip.hover.left="capitalize($t(item.type))"
            :icon="getSupplierInvoiceTypeIcon(item.type).icon"
        />
      </template>
      <template #listView_cell_paymentMethod="{item}">
        {{ capitalize($t(item.paymentMethod.name)) }}
      </template>
      <template #listView_cell__reconciliationStatus="{item}">
        <b-badge
            v-if="item._reconciliationStatus != null"
            pill
            :variant="getBadgeColorReconciliationStatus(item)"
        >
          <span v-if="item._reconciliationStatus == 'Unlettered'">
            {{
              $tc('Unlettered:',
                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
                  {
                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')
                  }
              )
            }}
          </span>
          <span v-else>
            {{ $t(item._reconciliationStatus) }}
          </span>
        </b-badge>
      </template>

      <template #listView_cell__bankReconciliationStatus="{item}">
        <b-badge
            v-if="item._bankReconciliationStatus != null"
            pill
            :variant="getBadgeColorBankReconciliationStatus(item)"
        >
          <span v-if="item._reconciliationStatus != 'Open' && item._bankReconciliationStatus == 'Unpaid'">
            {{
              $tc('Unpaid:',
                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
                  {
                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')
                  }
              )
            }}
          </span>
          <span v-else>
            {{ $t(item._bankReconciliationStatus) }}
          </span>
        </b-badge>
      </template>
      <template #listView_cell__export="{item}">
        <span
            v-if="item._export != null"
            @click.stop="downloadByDocument(item._export.document)"
        >{{ item._export.date|moment('MMMM YYYY')|capitalize }}
        </span>
        <span v-else>-</span>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editSupplierInvoiceLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteSupplierInvoiceAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :is-disabled="item._linkedElements.length > 0"
            size="sm"
        />

      </template>

      <template #leftViewWindow="{item}">
        <supplierInvoice-card
            :incoming-invoice="item"
            :is-viewable="true"
            :is-selected="item.id==displayedSupplierInvoice.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedSupplierInvoice.billNumber }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <button-edit
            @click.native.stop="editSupplierInvoiceLocal(displayedSupplierInvoice)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <supplier-invoice-view :supplier-invoice="displayedSupplierInvoice"/>
      </template>
    </base-template>

    <!-- modal supplier invoice -->
    <modal-incoming-invoice
        :incoming-invoice.sync="currentSupplierInvoice"
        :title="('id' in currentSupplierInvoice)?$t('EditInvoice'):$t('NewIncomingInvoice')"
        :is-open.sync="supplierInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedSupplierInvoiceLocal"
    />

    <!-- modal supplier invoice by upload-->
    <modal-upload
        :single-file.sync="newSupplierInvoiceFile"
        :document-type="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :is-open.sync="supplierInvoiceByUploadModalShow"
    />

  </div>
</template>

<script>
import { ref, computed, watch }                      from '@vue/composition-api'
import { capitalize }                                from '../../utils/filter'
import { downloadByDocument, getRoute, getUserData } from '../../utils/utils'
import { useSupplierInvoices }                       from '../../views/purchases/supplierInvoice/useSupplierInvoices'

import useAPI from '../../utils/useAPI'
import store  from '../../store'
import moment from 'moment'

import SupplierInvoiceView  from './SupplierInvoiceView.vue'
import BaseTemplate         from '../base3/Base.vue'
import SupplierInvoiceCard  from '../card/IncomingInvoice2.vue'
import ButtonRemove         from '../button/Remove.vue'
import ButtonEdit           from '../button/Edit.vue'
import ModalIncomingInvoice from '../prompt/IncomingInvoice.vue'
import ModalUpload          from '../prompt/Upload.vue'
import i18n                 from '../../libs/i18n'
import useAccountingLineApi from '../../store/accountingLine/useAccountingLineApi'
import useDepartmentApi     from '../../store/department/useDepartmentApi'

export default {
  components: {
    ModalUpload,
    ButtonEdit,
    ButtonRemove,
    SupplierInvoiceCard,
    BaseTemplate,
    SupplierInvoiceView,
    ModalIncomingInvoice
  },
  props: {
    indicatorStart: {
      type: String,
    },
    indicatorEnd: {
      type: String,
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('supplierInvoices')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedSupplierInvoice = ref(null)
    const currentSupplierInvoice = ref(null)
    const supplierInvoiceModalShow = ref(false)
    const supplierInvoiceByUploadModalShow = ref(false)
    const newSupplierInvoiceFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { incomingInvoices2, incomingInvoicesContext, autocompleteWorkFlos } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      if (getRoute().name == 'WorkFlo view') {
        emit('updatePayload', val)
      } else {
        fetchIncomingInvoices2(val)
            .then(() => {
              if (!isAutocompleteLoaded.value) {
                loadInit()
              }
            })
      }
    }, { deep: true })

    watch(supplierInvoiceModalShow, val => {
      if (val == false) {
        resetSupplierInvoice()
      }
    })
    watch(newSupplierInvoiceFile, (val) => {
      if (val != null) {
        recognizeIncomingInvoice(val)
            .then(response => {
              supplierInvoiceByUploadModalShow.value = false
              newSupplierInvoiceFile.value = null
              currentSupplierInvoice.value = response.data
              currentSupplierInvoice.value.accountingDivisions= [
                {
                  accountingDivisionAttachments: [
                    {
                      workflow: null,
                      workFlo: null,
                      department: null,
                      awarding: '100'
                    }
                  ],
                  accountingLine: 251, // 601
                  preTaxAmount: '0',
                  vatAmount: '0',
                  totalAmount: '0'
                },
                {
                  accountingDivisionAttachments: [
                    {
                      workflow: null,
                      workFlo: null,
                      department: null,
                      awarding: '100'
                    }
                  ],
                  accountingLine: 981, // 4456615
                  preTaxAmount: '0',
                  vatAmount: '0',
                  totalAmount: '0'
                }
              ]

              supplierInvoiceModalShow.value = true
            })
      }
    })

    watch(autocompleteWorkFlos, val => {
      resetSupplierInvoice()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchIncomingInvoices2,
      fetchAutocompleteArticles,
      fetchAutocompleteBankAccounts,
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteVats,
      fetchAutocompleteWorkflows,
      fetchAutocompleteUsers,
      fetchAutocompleteWorkFlos,
        fetchAutocompleteEmployees
    } = useAPI()
    const { fetchHierarchicalAccountingLines } = useAccountingLineApi()
    const { fetchHierarchicalDepartments } = useDepartmentApi()
    const {
      submitValidatedIncomingInvoice,
      removeIncomingInvoice,
      recognizeIncomingInvoice
    } = useSupplierInvoices()

    const loadInit = () => {
      fetchAutocompleteContacts()
      fetchAutocompletePaymentMethods()
      fetchAutocompleteBankAccounts()
      fetchAutocompleteArticles()
      fetchAutocompleteVats()
      fetchAutocompleteWorkFlos()
      fetchAutocompleteWorkflows()
      fetchHierarchicalAccountingLines()
      fetchHierarchicalDepartments()
      fetchAutocompleteEmployees()
      fetchAutocompleteUsers({
        searchCriteria: [{
          column_name: 'invitationCode',
          comparator: 'notNull'
        }, {
          column_name: 'roles',
          comparator: '!=',
          search_text: '[]',
        }, {
          column_name: 'password',
          comparator: 'notNull'
        },]
      })

      isAutocompleteLoaded.value = true
    }

    const resetSupplierInvoice = () => {
      currentSupplierInvoice.value = JSON.parse(JSON.stringify(store.getters['incomingInvoice2/getEmptyIncomingInvoice']))

      if (getRoute().name == 'WorkFlo view') {
        if (!autocompleteWorkFlos.value.length) {
          currentSupplierInvoice.value.workFlo = {
            id: parseInt(getRoute().params.workFloId)
          }
        } else {
          currentSupplierInvoice.value.workFlo = store.getters['workFlo/getAutocompleteWorkFlos'].find(w => w.id == parseInt(getRoute().params.workFloId))
        }
      }
    }

    const editSupplierInvoiceLocal = (supplierInvoice) => {
      store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
          .then(response => {
            currentSupplierInvoice.value = response
            supplierInvoiceModalShow.value = true
          })
    }

    const removeSupplierInvoiceLocal = (supplierInvoice) => {
      removeIncomingInvoice(supplierInvoice)
          .then(response => {

          })
    }

    const submitValidatedSupplierInvoiceLocal = () => {
      // console.log(currentSupplierInvoice.value)
      submitValidatedIncomingInvoice(currentSupplierInvoice.value)
          .then(response => {
            resetSupplierInvoice()
            supplierInvoiceModalShow.value = false
          })
    }

    const showDetails = (supplierInvoice) => {
      if (supplierInvoice != null) {
        store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
            .then(response => {
              displayedSupplierInvoice.value = response
            })

      } else {
        resetSupplierInvoice()
      }
    }

    const getSupplierInvoiceTypeIcon = (type) => {
      if (type == 'toll') return { variant: 'secondary', icon: 'car-side' }
      else if (type == 'catering') return { variant: 'secondary', icon: 'utensils' }
      else if (type == 'accommodation') return { variant: 'secondary', icon: 'suitcase-rolling' }
      else if (type == 'production') return { variant: 'secondary', icon: 'tools' }
      else return { variant: 'secondary', icon: 'wallet' }
    }

    const getBadgeColorReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Lettered') {
        return 'success'
      } else if (item._reconciliationStatus == 'Partially lettered') {
        return 'warning'
      } else if (item._reconciliationStatus == 'Unlettered') {
        return 'danger'
      }
    }

    const getBadgeColorBankReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Open') {
        return 'secondary'
      } else {
        if (item._bankReconciliationStatus == 'Paid') {
          return 'success'
        } else if (item._bankReconciliationStatus == 'Partially paid') {
          return 'warning'
        } else if (item._bankReconciliationStatus == 'Unpaid') {
          return 'danger'
        }
      }
    }

    const getDaysRemaining = (dueDate) => {
      return parseInt(moment.duration(moment(dueDate).diff(moment().startOf('day'))).asDays())
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetSupplierInvoice()

    if (getRoute().name != 'WorkFlo view') {
      if (getRoute().name == 'Indicators') {
        store.dispatch('moduleView/getModuleViews')
            .then((response) => {
              let currentView = store.getters['moduleView/getCurrentModuleView']('supplierInvoices')
              // let currentYear = parseInt(new Date().getFullYear()) - 1
              // let start = moment(currentYear + '-07-01').format('YYYY-MM-DD')
              // let end = moment((currentYear + 1) + '-06-30').format('YYYY-MM-DD')

              // console.log(props.indicatorStart)
              // console.log(props.indicatorEnd)

              payload.value = {
                filters: [{
                  sectionLogicOperator: 'AND',
                  sectionFilters: [{
                    logicOperator: 'AND',
                    field: 'billingDate',
                    operator: 'between',
                    value: [props.indicatorStart, props.indicatorEnd],
                    _fieldDisabled: true,
                    _sectionOperatorDisabled: true,
                    _allowDelete: true
                  }]
                }],
                kanbanSortBy: currentView.kanbanSortBy,
                kanbanColumnAmount: currentView.kanbanColumnAmount,
                listGlobalAmount: currentView.listGlobalAmount,
                page: 1,
                sorts: [{ 'column': 'billingDate', 'order': 'ASC' }],
              }

            })

      } else {
        store.dispatch('moduleView/getModuleViews')
            .then((response) => {
              let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

              // console.log(store.getters['incomingInvoice2/getAvailableGlobalAmounts'])

              payload.value = {
                filters: currentView.filters,
                kanbanSortBy: currentView.kanbanSortBy,
                kanbanColumnAmount: currentView.kanbanColumnAmount,
                listGlobalAmount: currentView.listGlobalAmount,
                page: 1,
                sorts: currentView.sorts,
              }

            })
      }
    } else {
      let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

      payload.value = {
        filters: currentView.filters,
        kanbanSortBy: currentView.kanbanSortBy,
        kanbanColumnAmount: currentView.kanbanColumnAmount,
        listGlobalAmount: currentView.listGlobalAmount,
        page: 1,
        sorts: currentView.sorts,
      }
    }

    return {
      // Components
      capitalize,

      // Data
      module,
      payload,
      isReady,

      displayedSupplierInvoice,
      currentSupplierInvoice,
      supplierInvoiceModalShow,
      supplierInvoiceByUploadModalShow,
      newSupplierInvoiceFile,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,

      // Methods
      editSupplierInvoiceLocal,
      removeSupplierInvoiceLocal,
      submitValidatedSupplierInvoiceLocal,
      showDetails,
      getSupplierInvoiceTypeIcon,
      getBadgeColorReconciliationStatus,
      getBadgeColorBankReconciliationStatus,
      getDaysRemaining,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {
    downloadByDocument,
    deleteSupplierInvoiceAlert (supplierInvoice) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSupplierInvoice') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeSupplierInvoiceLocal(supplierInvoice)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
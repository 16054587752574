<template>
  <b-row>
    <b-col md="2">
      <dates-card
          :start.sync="start"
          :end.sync="end"
      />
    </b-col>

    <b-col md="10">
      <b-row>
        <b-col md="6">
          <sales-general
              :start="start"
              :end="end"
              :salesSeries.sync="salesSeries"
              :globalSeries="globalSeries"
          />
        </b-col>
        <b-col md="6">
          <expenses-general
              :start="start"
              :end="end"
              :expensesSeries.sync="expensesSeries"
              :globalSeries="globalSeries"
          />
        </b-col>
      </b-row>
    </b-col>


    <b-col md="12">
      <!--      <revenue-card/>-->
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import i18n from '@/libs/i18n'
import moment from 'moment'
import RevenueCard from '@/components/indicators/revenue/RevenueCard'
import SalesGeneral from '../../components/indicators/sales/SalesGeneral'
import ExpensesGeneral from '../../components/indicators/expenses/ExpensesGeneral'
import DatesCard from './components/_dates'
import { getCurrentFinancialYear } from '../../utils/utils'

export default {
  components: {
    SalesGeneral,
    RevenueCard,
    ExpensesGeneral,
    DatesCard
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const salesSeries = ref(null)
    const expensesSeries = ref(null)

    // const currentYear = parseInt(new Date().getFullYear())-1
    const start = ref(getCurrentFinancialYear().start)
    const end = ref(moment().format('YYYY-MM-DD'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const globalSeries = computed(() => {
      return [
        {
          name: capitalize(i18n.t('incoming')),
          data: salesSeries.value
        },
        {
          name: capitalize(i18n.t('outgoing')),
          data: expensesSeries.value
        },
      ]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const changeMonth = (previousOrNext) => {
    //   if (previousOrNext == 'previous') {
    //     start.value = moment(start.value).subtract(1,'months').format('YYYY-MM-DD')
    //     // monthStr.value = moment(monthStr.value+'-01').subtract(1,'months').format('YYYY-MM')
    //   } else {
    //     end.value = moment(end.value).add(1,'months').format('YYYY-MM-DD')
    //     // monthStr.value = moment(monthStr.value+'-01').add(1,'months').format('YYYY-MM')
    //   }
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      salesSeries,
      expensesSeries,
      start,
      end,

      // Computed
      globalSeries,

      // Methods
      // changeMonth,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.elementsToDetail)?_c('b-modal',{attrs:{"title":_vm.title,"size":"xl","scrollable":"","hide-footer":""},model:{value:(_vm.isOpenLocal),callback:function ($$v) {_vm.isOpenLocal=$$v},expression:"isOpenLocal"}},[_c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$t('incoming'))}},[_c('b-card-text',[_c('sales-indicators',{attrs:{"sales":_vm.elementsToDetail.sales}})],1)],1),_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$t('outgoing'))}},[_c('b-card-text',[_c('expenses-indicators',{attrs:{"expenses":_vm.elementsToDetail.expenses}})],1)],1),_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$t('awaitingExportOutgoing'))}},[_c('b-card-text',[_c('awaiting-export-sales-indicators',{attrs:{"date":_vm.date}})],1)],1),_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$t('awaitingExportIncoming'))}},[_c('b-card-text',[_c('awaiting-export-expenses-indicators',{attrs:{"date":_vm.date}})],1)],1),_c('b-tab',{attrs:{"title":_vm.capitalize(_vm.$tc('documents',2))}},[_c('b-card-text',[_c('document-content',{attrs:{"document":{
            name: _vm.$t('LiaisonForm') + ' (Excel)',
            fileName: _vm.title,
            extension: 'xlsx'
            },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadLiaisonForm,"click":_vm.downloadLiaisonForm}}),_c('document-content',{attrs:{"document":{
            name: _vm.$t('PurchasesMerging'),
            fileName: _vm.capitalize(_vm.$t('purchases:')) + ' ' + _vm.title,
            extension: 'pdf'
            },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadDetailPurchases,"click":_vm.downloadDetailPurchases}}),_c('document-content',{attrs:{"document":{
            name: _vm.$t('SalesMerging'),
            fileName: _vm.capitalize(_vm.$t('sales:')) + ' ' + _vm.title,
            extension: 'pdf'
            },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadDetailSales,"click":_vm.downloadDetailSales}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
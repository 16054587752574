<template>
  <b-row>
    <b-col cols="12">
      <div
          id="map"
          class="map"/>
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import 'leaflet/dist/leaflet.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const items = ref([])

    const mapX = ref(null)
    const tileLayer = ref(null)
    const mapUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png'
    const mapAttributions = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'

    const addressMarkerObjects = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      items,
      mapX,
      tileLayer,
      mapUrl,
      mapAttributions,
      addressMarkerObjects

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    populateItems (filteredItems) {
      this.items = JSON.parse(JSON.stringify(filteredItems))
      this.populateAddresObjects()
    },
    initMap () {
      this.map = L.map('map').setView([47, 2], 6)
      this.tileLayer = L.tileLayer(this.mapUrl, {
        maxZoom: 18,
        attribution: this.mapAttributions
      })
      this.tileLayer.addTo(this.map)
    },

    populateAddresObjects () {
      this.resetMap()
      this.items.forEach(item => {
        item._toMap.forEach(address => {
          if (address.latitude && address.longitude) {
            let leafletObject = L.marker(
                [address.latitude, address.longitude],
                {
                  // icon: this.setIcon(layer.color)
                }
            ).bindTooltip(item._display).openTooltip();
            this.addressMarkerObjects.push(leafletObject)

            leafletObject.addTo(this.map)
          }

        })
      })
    },
    resetMap() {
      this.addressMarkerObjects.forEach(leafletObject => {
        leafletObject.removeFrom(this.map)
      })
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
.map {
  height: 660px
}
</style>
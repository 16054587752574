<template>
  <div>

    <b-row>
      <!-- Contact -->
      <b-col cols="12"
             class="d-flex">
        <field-contact
            :name="$t('supplier')"
            :contact.sync="outgoingInvoice.customerCompany"
            :is-initial-contact-company="true"
            :is-company-only="true"
            :is-required="true"
        />
<!--        <field-contact-->
<!--            :customerCompany.sync="outgoingInvoice.customerCompany"-->
<!--            :customerIndividual.sync="outgoingInvoice.customerIndividual"-->
<!--            :isRequired="true"-->
<!--            @updated="updateDeliveryAddresses"-->
<!--            name="delivery"-->
<!--            class="w-100"-->
<!--        />-->

        <field-switch
            class="mt-50 ml-50"
            :model.sync="isSameBillingAndDeliveryAddress"
            position="right"
            name="sameBillingAddress"
        />
      </b-col>

      <!-- Delivery address -->
      <b-col          md="6">
        <field-select
            :key="componentKey"
            v-if="outgoingInvoice.customerCompany != null || outgoingInvoice.customerIndividual != null"
            :model.sync="outgoingInvoice.deliveryAddress"
            :name="'deliveryAddress'"
            :options="deliveryAddresses"
            :label="'_display'"
            :isRequired="true"
            @updated="$emit('updateVatAllowed')"
        />
      </b-col>

      <!-- Billing address -->
      <b-col
          v-if="isSameBillingAndDeliveryAddress"
          md="6"
      >

        <field-select
            :key="componentKey"
            v-if="outgoingInvoice.customerCompany != null || outgoingInvoice.customerIndividual != null"
            :model.sync="outgoingInvoice.billingAddress"
            :name="'billingAddress'"
            :options="deliveryAddresses"
            :label="'_display'"
            :isRequired="true"
        />
      </b-col>

      <!--      Is not same address delivery/invoicing-->
      <b-col
          cols="12"
          v-if="!isSameBillingAndDeliveryAddress"
      >
        <field-contact
            :customerCompany.sync="outgoingInvoice.billingCompany"
            :isRequired="true"
            @updated="updateBillingAddresses"
            name="billing"
        />
      </b-col>

      <b-col
          cols="12"
          :key="componentKey"
          v-if="!isSameBillingAndDeliveryAddress"
      >
        <field-select
            v-if="outgoingInvoice.billingCompany != null"
            :model.sync="outgoingInvoice.billingAddress"
            name="billingAddress"
            :options="billingAddresses"
            :label="'_display'"
            :isRequired="true"
            :key="componentKey"
            @updated="$emit('updateVatAllowed')"
        />
      </b-col>


    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '../../../../store'
import FieldContact from '../../../input/Contact2.vue'
import FieldSwitch from '../../../input/Switch'
import FieldSelect from '../../../input/Select'

export default {
  components: {
    FieldContact,
    FieldSwitch,
    FieldSelect
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isSameBillingAndDeliveryAddress = ref(true)
    const deliveryAddresses = ref([])
    const billingAddresses = ref([])
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isSameBillingAndDeliveryAddress, (val) => {
      props.outgoingInvoice.billingCompany = null
      props.outgoingInvoice.billingAddress = null
    })

    watch(() => props.outgoingInvoice.customerCompany, val => {
      // console.log(val)

      if ('_uuid' in val) {
        store.dispatch('company/getCompany', val.id)
            .then(company => {
              // console.log(company)
              props.outgoingInvoice.customerCompany = company
              updateDeliveryAddresses()
            })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateDeliveryAddresses = () => {
      console.log(props.outgoingInvoice)
      props.outgoingInvoice.deliveryAddress = null
      props.outgoingInvoice.billingAddress = null

      if (props.outgoingInvoice.customerCompany != null) {
        if (
            ('addresses' in props.outgoingInvoice.customerCompany) &&
            props.outgoingInvoice.customerCompany.addresses.length > 0
        ) {
          deliveryAddresses.value = props.outgoingInvoice.customerCompany.addresses
          props.outgoingInvoice.deliveryAddress = props.outgoingInvoice.customerCompany.addresses[0].id
          // console.log(deliveryAddresses.value)
          props.outgoingInvoice.billingAddress = props.outgoingInvoice.customerCompany.addresses[0].id

        } else {
          deliveryAddresses.value = []
        }

      } else if (props.outgoingInvoice.customerIndividual != null) {
        deliveryAddresses.value = props.outgoingInvoice.customerIndividual.addresses
        if (props.outgoingInvoice.customerIndividual.company && 'addresses' in props.invoice.customerIndividual.company) {
          deliveryAddresses.value = deliveryAddresses.value.concat(props.outgoingInvoice.customerIndividual.company.addresses)
        }

        if (props.outgoingInvoice.customerIndividual.addresses.length > 0) {
          props.outgoingInvoice.billingAddress = props.outgoingInvoice.customerIndividual.addresses[props.invoice.customerCompany.addresses.length - 1].id
          props.outgoingInvoice.deliveryAddress = props.outgoingInvoice.customerIndividual.addresses[props.invoice.customerCompany.addresses.length - 1].id
        }
      } else {
        console.log("in")
        deliveryAddresses.value = []
      }

      emit('updateVatAllowed')

      componentKey.value++
    }

    const updateBillingAddresses = () => {
      // console.log(props.outgoingInvoice)
      componentKey.value++
      if (props.outgoingInvoice.billingCompany != null) {
        if (
            ('addresses' in props.outgoingInvoice.billingCompany) &&
            props.outgoingInvoice.billingCompany.addresses.length > 0
        ) {
          billingAddresses.value = props.outgoingInvoice.billingCompany.addresses
          props.outgoingInvoice.billingAddress = props.outgoingInvoice.billingCompany.addresses[0].id
        } else {
          billingAddresses.value = []
        }

        if (!('addresses' in props.outgoingInvoice.billingCompany)) {
          store.dispatch('company/getCompany', props.outgoingInvoice.billingCompany.id)
              .then(company => {
                props.outgoingInvoice.billingCompany = company
                return company
              })
        } else {
          return props.outgoingInvoice.billingCompany
        }


      } else {
        billingAddresses.value = []
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (
        props.outgoingInvoice.billingCompany != null &&
        props.outgoingInvoice.customerCompany != null &&
        props.outgoingInvoice.billingCompany.id != props.outgoingInvoice.customerCompany
    ) {
      isSameBillingAndDeliveryAddress.value = false
    }

    if (props.outgoingInvoice.customerCompany) {
      store.dispatch('company/getCompany', props.outgoingInvoice.customerCompany.id)
          .then(company => {
            // console.log(company)
            deliveryAddresses.value = company.addresses
            billingAddresses.value = company.addresses
          })
    }

    return {
      // Components

      // Data
      isSameBillingAndDeliveryAddress,
      deliveryAddresses,
      billingAddresses,
      componentKey,

      // Computed
      // selectedDeliveryContact,
      // selectedBillingContact,

      // Methods
      updateDeliveryAddresses,
      updateBillingAddresses,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>
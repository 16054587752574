import store from '../index'
import { computed } from '@vue/composition-api'

export default function useAccountingLineApi () {
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================

  const fetchAutocompleteAccountingLines = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('accountingLine/getAutocompleteAccountingLines', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchAccountingLines = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('accountingLine/getAccountingLines', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchHierarchicalAccountingLines = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('accountingLine/getHierarchicalAccountingLines', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const autocompleteAccountingLines = computed(() => {
    return store.getters['accountingLine/getAutocompleteAccountingLines']
  })

  const accountingLines = computed(() => {
    return store.getters['accountingLine/getAccountingLines']
  })

  const accountingLinesContext = computed(() => {
    return store.getters['accountingLine/getAccountingLinesContext']
  })

  const hierarchicalAccountingLines = computed(() => {
    return store.getters['accountingLine/getHierarchicalAccountingLines']
  })


  return {
    // Fetchers
    fetchAutocompleteAccountingLines,
    fetchAccountingLines,
    fetchHierarchicalAccountingLines,

    // Computed
    autocompleteAccountingLines,
    accountingLines,
    accountingLinesContext,
    hierarchicalAccountingLines
  }
}
<template>
  <div>
    <b-row>
      <b-col md="4">

      </b-col>
      <b-col md="8">
        <form-accounting-division
            v-for="(accountingDivision, index) in incomingInvoiceLocal.accountingDivisions"
            :accounting-division.sync="accountingDivision"
            :index="index"
            class="mb-1"

            @remove="removeAccountingDivision(index)"
        />

        <add-button
            size="sm"

            @click="addAccountingDivision"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import FormAccountingDivision   from '../../accountingDivision/FormAccountingDivision.vue'
import DefaultButton            from '../../../button/Button.vue'
import AddButton                from '../../../button/Add.vue'
import { clone }                from '../../../../utils/utils'

export default {
  components: { AddButton, DefaultButton, FormAccountingDivision },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const incomingInvoiceLocal = computed({
      get () {
        return props.incomingInvoice
      },
      set (val) {
        emit('update:incomingInvoice', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addAccountingDivision = () => {
      incomingInvoiceLocal.value.accountingDivisions.push({
        accountingDivisionAttachments: [],
      })
    }

    const removeAccountingDivision = index => {
      incomingInvoiceLocal.value.accountingDivisions.splice(index, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      incomingInvoiceLocal,

      // Methods
      addAccountingDivision,
      removeAccountingDivision,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
import store from '@/store'

export const useCustomerInvoices = () => {
  const submitValidatedOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      if ('id' in outgoingInvoice) {
        updateOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/addOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/updateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/removeOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const validateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/validateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOutgoingInvoice = (outgoingInvoice) => {
    store.dispatch('outgoingInvoice2/printOutgoingInvoice', outgoingInvoice)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      })
  }

  const downloadDeliveryNote = (outgoingInvoice) => {
    store.dispatch('outgoingInvoice2/printDeliveryNote', outgoingInvoice)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      })
  }

  const outgoingInvoicePaymentBannerColor = (outgoingInvoice) => {
    if (outgoingInvoice._paymentStatus == 'Lettered') {
      return "success"
    } else if (outgoingInvoice._paymentStatus == 'Partially lettered') {
      return "warning"
    } else if (outgoingInvoice._paymentStatus == 'Unlettered') {
      return "danger"
    }


    // if (outgoingInvoice._balance == 0) {
    //   return 'success'
    // }
    //
    // if (
    //   outgoingInvoice._paymentStatus == 'Waiting for payment' ||
    //   outgoingInvoice._paymentStatus == 'Open'
    // ) {
    //   return 'secondary'
    // } else if (outgoingInvoice._paymentStatus == 'Partial payment') {
    //   return 'warning'
    // } else if (outgoingInvoice._paymentStatus == 'Unpaid') {
    //   return 'danger'
    // }
  }

  return {
    submitValidatedOutgoingInvoice,
    removeOutgoingInvoice,
    validateOutgoingInvoice,
    downloadDeliveryNote,
    downloadOutgoingInvoice,
    outgoingInvoicePaymentBannerColor,
  }
}